<template>
  <div class="dialog-alert-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogVisible"
      @close="close"
    >
      <div class="dialog-contain">
        <div class="header-contain">
          <slot name="header">
            <div class="title-contain">
              <span class="title">{{ title }}</span>
            </div>

            <button
              type="button"
              aria-label="Close"
              class="el-dialog__headerbtn"
              @click="closeClick"
            >
              <i
                class="el-dialog__close el-icon el-icon-close"
                style="color:#909399;"
              ></i>
            </button>
          </slot>
        </div>

        <div class="dialog-body-contain">
          <slot name="body">
            <el-input
              oninput="value=value.replace(/[^\d.]/g,'')"
              v-model="input"
              type="text"
              clearable
              :placeholder="'请输入' + title"
            ></el-input>
          </slot>
        </div>

        <div class="dialog-bottom-contain">
          <slot name="footer">
            <div class="button-contain">
              <en-button size="mini" class="button" type="wihte" @click="close">
                取 消
              </en-button>
              <en-button size="mini" class="button" @click="confirmClick">
                确 定
              </en-button>
            </div>
          </slot>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "标题"
    },
    initValue: {
      type: String,
      default: ""
    }
  },
  data() {
    return { input: "" };
  },
  watch: {},
  mounted() {
    this.input = this.initValue;
  },
  methods: {
    checkPrice() {
      let price = `${this.price}`;
      price = price
        .replace(/[^\d.]/g, "") // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, ".") // 只保留第一个. 清除多余的
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
      if (price.indexOf(".") < 0 && price !== "") {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        price = parseFloat(price);
      }
      this.price = price;
    },
    closeClick() {
      this.$emit("update:dialogVisible", false);
    },
    confirmClick() {
      this.closeClick();
      this.$emit("getInputDialogConfirm", this.input);
    },
    close() {
      this.closeClick();
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-contain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 350px;
  background-color: #fff;
  border-radius: 5px;
  max-height: 100%;
  .header-contain {
    border-radius: 5px 5px 0 0;
    padding: 10px 20px 0;
    height: 50px;
    line-height: 40px;
    // border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    text-align: left;
    .title-contain {
      .title {
        font-size: 14px !important;
        font-family: "PingFangSC-Light", "Hiragino Sans GB",
          Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
      }
    }
  }
  .dialog-body-contain {
    margin: 10px 20px;
    text-align: left;
    height: 50px;

    & /deep/ .el-input__inner {
      padding-left: 12px;
    }
    & /deep/ .el-input__icon {
      line-height: 32px !important;
    }
  }
  .dialog-bottom-contain {
    padding: 0;
    margin: 10px 20px 20px;
    text-align: left;
    .button-contain {
      text-align: right;
    }
  }
}
</style>
<style lang="css" scoped>
.dialog-alert-contain >>> .el-dialog__header {
  padding: 0;
  margin: 0;
  height: 0;
}
.dialog-alert-contain >>> .el-dialog__body {
  padding: 0;
  margin: 0;
}
.dialog-alert-contain >>> .el-pagination {
  text-align: center;
}
</style>
