<template>
  <div class="hotel-container" style="overflow-y: auto;">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
    >
      <!-- 酒店信息 -->
      <sectionTitle section-name="酒店信息" />

      <!-- 酒店名称 -->
      <el-form-item prop="name">
        <el-input v-model="form.name" clearable placeholder="酒店名称">
          <en-icon
            slot="prefix"
            name="jiudianyuding-xianxing"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <separatorLine />

      <!-- 时间地点信息 -->
      <sectionTitle section-name="时间地点" />

      <!-- 入住日期  -->
      <el-form-item prop="arrivalDate">
        <div style="position: relative;">
          <el-date-picker
            v-model="form.arrivalDate"
            type="date"
            placeholder="入住日期"
          >
          </el-date-picker>
          <en-icon
            name="riqi-shanglvguanli"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <!-- 离店日期 -->
      <el-form-item prop="departureDate">
        <div style="position: relative;">
          <el-date-picker
            v-model="form.departureDate"
            type="date"
            placeholder="离店日期"
          >
          </el-date-picker>
          <en-icon
            name="lidianriqi"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <!-- 入住城市 -->
      <el-form-item prop="arrivalCity">
        <div style="position: relative;">
          <en-city v-model="form.arrivalCity" placeholder="入住城市"></en-city>
          <en-icon
            name="daodachengshi-budan"
            size="16px"
            class="city-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <separatorLine />

      <!-- 人员信息 -->
      <sectionTitle section-name="入住人员" />
      <userList :user-list.sync="form.userList" />
      <separatorLine />

      <!-- 合计金额 -->
      <sectionTitle section-name="合计金额" />
      <el-form-item prop="totalAmount">
        <el-input
          v-model="form.totalAmount"
          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
          clearable placeholder="合计金额"
          maxlength="10"
        >
          <en-icon
            slot="prefix"
            name="hejijine-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <separatorLine />

      <!-- 事项说明 -->
      <sectionTitle section-name="事项说明" />
      <el-form-item prop="explain">
        <div style="position: relative;">
          <el-input
            v-model="form.explain"
            type="textarea"
            placeholder="事项说明"
            maxlength="100"
          >
          </el-input>
          <en-icon
            name="shixiangshuoming-budan"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Store from "@/store";
import enCity from "../../components/en-city";
import separatorLine from "../components/separator-line";
import sectionTitle from "../components/section-title";
import userList from "../components/user-list";

export default {
  name: "Hotel",
  components: {
    separatorLine,
    sectionTitle,
    userList,
    enCity
  },
  props: {
    hotelData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // form数据
      form: {
        type: "001",
        code: "",
        classType: "000",
        name: "",
        arrivalDate: "",
        departureDate: "",
        startCity: {
          enCityName: ""
        },
        arrivalCity: {
          enCityName: ""
        },
        totalAmount: "",
        explain: "",
        userId: "",
        userList: [

        ]
      },
      rules: {
        // 添加验证
        name: [
          {
            required: true,
            message: "请输入酒店名称",
            trigger: "blur"
          },
          {
            max: 20,
            message: "最多输入20个字符",
            trigger: "blur"
          }
        ],
        arrivalDate: [
          {
            required: true,
            message: "请选择入住日期",
            trigger: "blur"
          }
        ],
        departureDate: [
          {
            required: true,
            message: "请选择离店日期",
            trigger: "blur"
          }
        ],
        totalAmount: [
          {
            required: true,
            message: "输入合计金额",
            trigger: "blur"
          }
        ],
        explain: [
          {
            required: true,
            message: "输入事项说明",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    if (this.hotelData) {
      this.form = this.hotelData;
    } else {
      this.form.userList.push({
        userId: Store.getters.userInfo.userId,
        name: Store.getters.userInfo.name
      });
    }
  },
  beforeDestroy() {
    this.$emit("update:hotelData", this.form);
  },
  methods: {
    parentHandleClick(val) {
      console.log(val);
      /* eslint-disable */
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.userList?.length === 0) {
            this.$alert("请添加人员");
          } else {
            this.$emit("commitData", this.form);
          }
        } else {
          this.$alert("请根据提示完成对应内容的填写或选择");
          return false;
        }
      });
      /* eslint-enable */
    }
  }
};
</script>

<style lang="scss" scoped>
.hotel-container {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  .form-section {
    margin: 19px 20px 14px 21px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #333;
  }
  .custom-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .city-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .en-prefix {
    margin-top: 7px;
    margin-right: 6px;
    fill: #a9b5c6;
  }
  & /deep/ .en-city-panel {
    width: 820px;
    left: 0;
  }
  & /deep/ .el-input,
  .el-select {
    margin-bottom: 14px;
  }
  & /deep/ .el-form-item {
    min-height: 46px;
    margin-left: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .el-form-item__label {
      display: none;
    }
    .el-input {
      margin-bottom: 14px;
    }
  }
  & /deep/ .el-input__inner,
  .el-textarea__inner {
    width: 820px;
    padding-left: 12px;
  }
  & /deep/ .el-input__icon {
    line-height: 32px !important;
  }
  & /deep/ .el-date-editor {
    width: 820px;
    .el-input__prefix {
      display: none;
    }
  }
  & /deep/ .el-date-editor > .el-input__prefix {
    left: 6px !important;
  }
  & /deep/ .el-input__inner {
    padding-left: 34px;
  }
  & /deep/ .el-textarea {
    margin-bottom: 20px;
    width: 820px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #b9c4d2;
    .el-textarea__inner {
      padding-left: 34px;
    }
  }
}
</style>
