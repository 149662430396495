<template>
  <div style="background-color:#fff;">
    <div style="height:50px;"></div>
    <en-button @click="contactClick">
      常用联系人
    </en-button>

    <en-button @click="relationClick">
      关联行程
    </en-button>

    <en-button @click="supplementClick">
      补单
    </en-button>

    <enContact
      v-if="contactShow"
      :dialog-visible.sync="contactShow"
    />

    <enItinerary
      v-if="relationShow"
      :dialog-visible.sync="relationShow"
    />

    <supplement
      v-if="supplementShow"
      :dialog-visible.sync="supplementShow"
    />
  </div>
</template>

<script>
import enContact from "./components/en-contacts";
import enItinerary from "./components/en-itinerary";
import Supplement from "./supplement";

export default {
  name: "TestView",
  components: {
    enContact,
    enItinerary,
    Supplement
  },
  data() {
    return {
      contactShow: false,
      relationShow: false,
      supplementShow: false
    };
  },
  watch: {
    contactShow(val) {
      console.log(val);
    }
  },
  methods: {
    contactClick() {
      this.contactShow = true;
    },
    relationClick() {
      this.relationShow = true;
    },
    supplementClick() {
      this.supplementShow = true;
    }
  }
};
</script>
