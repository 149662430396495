<template>
  <div class="dialog-apply-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogApplyVisible"
    >
      <div
        class="dialog-content-contain"
        :style="contentWHStyle"
      >
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>

        <div class="operation-contain">
          <en-button
            type="text"
            icon-color="#3e90fe"
            icon="yiyouhangcheng"
            @click="operationTripClick"
          >
            行程单
          </en-button>
          <en-button
            type="text"
            icon-color="#3e90fe"
            icon="dayin-kuaijiecaozuo"
            @click="operationPrintingClick"
          >
            打印
          </en-button>
          <en-button
            type="text"
            icon-color="#A9B5C6"
            icon="niantiedan-wodehangcheng"
            @click="operationPasteClick"
          >
            粘贴单
          </en-button>
          <en-button
            type="text"
            icon-color="#FFAD2C"
            icon="gongxiang-wodehangcheng"
            @click="operationCommunicateClick"
          >
            沟通
          </en-button>
        </div>

        <div class="body-contain">
          <div class="detail-contain">
            <div class="detail-title">
              姓名：<div class="detail-content">
                张三
              </div>
            </div>
            <div class="detail-title">
              部门：<div class="detail-content">
                技术部
              </div>
            </div>
            <div class="detail-title">
              公司：<div class="detail-content">
                湖南英富莱网络技术有限公司
              </div>
            </div>
            <div class="detail-title">
              单号：<div class="detail-content">
                BD201908070002
              </div>
            </div>
            <div class="detail-title">
              时间：<div class="detail-content">
                2019-10-28
              </div>
            </div>
            <div class="detail-title detail-one-line">
              事由：<div class="detail-content">
                报销差旅费
              </div>
            </div>
            <div class="detail-title detail-one-line">
              内容：<div class="detail-content">
                出差预借差旅费1000元
              </div>
            </div>
          </div>

          <div
            ref="personDiv"
            class="person-contain"
          >
            <div class="person-title">
              发起审批
            </div>
            <div class="person-body-contain">
              <div class="person-list-contain">
                <div
                  v-for="item in personList"
                  :key="item.id"
                  class="person-item-contain"
                >
                  <en-user-logo
                    class="logo"
                    :user-name="item.name"
                    size="34"
                  ></en-user-logo>

                  <div class="name">
                    {{ item.name }}
                  </div>

                  <div @click="deleteUser(item)">
                    <en-icon
                      class="user-delete"
                      name="shibai"
                      size="14px"
                      :style="{fill:'#F76B6B'}"
                    ></en-icon>
                  </div>

                  <div
                    v-if="personList.indexOf(item) !== personList.length - 1"
                    class="jiantou"
                  >
                    →
                  </div>
                </div>

                <div class="add-item-contain">
                  <div
                    class="add-contain"
                    @click="addUserClick"
                  >
                    <en-icon
                      name="tianjia-anniutoubu"
                      size="10px"
                      :style="{fill:'#3e90fe'}"
                      class="add-icon"
                    ></en-icon>
                  </div>
                  <div class="add-text">
                    添加
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flow-contain">
            <div class="flow-title">
              审批流程
            </div>
            <div class="flow-list-contain">
              <div
                v-for="item in flowList"
                :key="item.id"
                class="flow-item-contain"
              >
                <div class="item-left-contain">
                  <div class="left-line"></div>
                  <en-icon
                    class="left-icon"
                    name="guize-liebiao"
                    size="16px"
                  ></en-icon>
                </div>
                <div class="item-right-contain">
                  <en-user-logo
                    class="logo"
                    user-name="测试"
                    size="40"
                  ></en-user-logo>
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="time">
                    {{ item.time }}
                  </div>
                  <div class="approval">
                    {{ item.approval }}
                  </div>
                  <div class="reason">
                    {{ item.reason }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-contain">
          <en-button
            class="button"
            @click="bottomClick"
          >
            收回
          </en-button>
        </div>
      </div>
    </el-dialog>

    <enContacts
      v-if="contactsVisible"
      :dialog-visible.sync="contactsVisible"
      @getEnContactConfirm="getSelectContact"
    />
  </div>
</template>

<script>
import enContacts from "../../../components/en-contacts";

export default {
  name: "EnApply",
  components: { enContacts },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogApplyVisible: true,
      screenWidth: "",
      screenHeight: "",
      contentWHStyle: "",
      title: "预借差旅费",
      personList: [
        { name: "临时工" },
        { name: "安卓大佬" },
        { name: "iOS小弟" }
      ],
      flowList: [
        {
          name: "临时工",
          time: "08-08 16:00",
          approval: "财务初审",
          reason: "申请金额超标"
        },
        {
          name: "安卓大佬",
          time: "08-08 20:00",
          approval: "经理审批",
          reason: "因客户较多"
        },
        {
          name: "iOS小弟",
          time: "08-09 09:00",
          approval: "财务复审",
          reason: "超标已备注"
        }
      ],
      contactsVisible: false
    };
  },
  watch: {
    dialogApplyVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    screenWidth() {
      this.changeWHFuction();
    },
    screenHeight() {
      this.changeWHFuction();
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    const self = this;
    window.onresize = () => (() => {
      self.screenWidth = document.body.clientWidth;
      self.screenHeight = document.body.clientHeight;
    })();
  },
  methods: {
    closeClick() {
      this.dialogApplyVisible = false;
    },
    changeWHFuction() {
      this.contentWHStyle = "";
      if (this.screenHeight < 900) {
        const height = this.screenHeight < 700 ? 600 : (this.screenHeight - 100);
        this.contentWHStyle = `height:${height}px;`;
      }
      if (this.screenWidth < 1600) {
        const width = this.screenWidth < 1160 ? 800 : (this.screenWidth - 360);
        this.contentWHStyle = `${this.contentWHStyle}width:${width}px;`;
      }
    },
    deleteUser(item) {
      const index = this.personList.indexOf(item);
      this.personList.splice(index, 1);
    },
    addUserClick() {
      console.log(this.contactsVisible);
      this.contactsVisible = true;

      // let height = window.getComputedStyle(this.$refs.personDiv).height;
      // let height1 = this.$refs.personDiv.offsetHeight;
      // let height2 = this.$refs.personDiv.style.height;
      // console.log(height + ":" + height1 + ":" + height2);
    },
    getSelectContact(val) {
      const obj = {
        id: val.id,
        name: val.name
      };
      this.personList.push(obj);
    },
    bottomClick() {
      this.$message("收回");
    },
    operationTripClick() {
      this.$message("行程单");
    },
    operationPrintingClick() {
      this.$message("打印");
    },
    operationPasteClick() {
      this.$message("粘贴单");
    },
    operationCommunicateClick() {
      this.$message("沟通");
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1100px;
  height: 820px;
  min-width: 800px;
  min-height: 600px;
  background-color: #f5f7fa;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    text-align: left;
    color: #333;
  }
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .operation-contain {
    position: absolute;
    padding-top: 8px;
    top: 0;
    right: 50px;
    height: 50px;
    display: flex;
    & /deep/ .el-button + .el-button {
      margin-left: 20px;
    }
  }
  .body-contain {
    margin-top: 52px;
    font-size: 12px;
    height: calc(100% - 52px - 64px);

    .detail-contain {
      margin: 0 20px;
      padding: 16px 20px 0;
      background-color: #fff;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .detail-title {
        display: block;
        color: #333;
        padding-right: 20px;
        padding-bottom: 16px;
        .detail-content {
          display: inline-block;
          color: #636c78;
        }
      }
      .detail-one-line {
        width: 100%;
        text-align: left;
      }
    }

    .person-contain {
      margin: 8px 20px 0 20px;
      background-color: #fff;
      border: 1px solid #e8ecf2;
      border-radius: 4px;

      .person-title {
        margin: 16px 20px 0;
        text-align: left;
        color: #333;
        font-weight: bold;
      }
      .person-body-contain {
        height: 88px;
        overflow-y: auto;
        .person-list-contain {
          margin: 0 20px 15px;
          display: flex;
          flex-wrap: wrap;
          .person-item-contain {
            position: relative;
            width: 64px;
            margin-top: 15px;
            text-align: left;
            .logo {
              margin-top: 3px;
            }
            .name {
              width: 50px;
              margin-left: -7px;
              padding-top: 8px;
              font-size: 12px;
              color: #606060;
              text-align: center;
            }
            .user-delete {
              position: absolute;
              top: 0;
              left: 24px;
            }
            .jiantou {
              position: absolute;
              top: 15px;
              right: 6px;
            }
          }
          .add-item-contain {
            .add-contain {
              margin-top: 18px;
              height: 34px;
              width: 34px;
              border: 1px solid #e9e9e9;
              border-radius: 50%;
              text-align: center;
              .add-icon {
                margin-top: 10px;
              }
            }
            .add-text {
              margin-top: 8px;
              color: #3e90fe;
            }
          }
        }
      }
    }

    .flow-contain {
      margin: 8px 20px 0 20px;
      background-color: #fff;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      height: calc(100% - 192px - 64px);
      .flow-title {
        padding: 0 20px;
        height: 49px;
        line-height: 49px;
        text-align: left;
        color: #333;
        font-weight: bold;
        border-bottom: 1px solid #e8ecf2;
      }
      .flow-list-contain {
        margin: 16px;
        max-height: calc(100% - 49px - 32px);
        overflow-y: auto;
        .flow-item-contain {
          height: 90px;
          .item-left-contain {
            position: relative;
            float: left;
            height: 90px;
            width: 32px;
            .left-line {
              position: absolute;
              top: 0;
              left: 8px;
              width: 2px;
              height: 90px;
              background-color: #e8ecf2;
            }
            .left-icon {
              position: absolute;
              top: 32px;
              left: 0;
              fill: #73ade8;
            }
          }
          .item-right-contain {
            position: relative;
            height: 80px;
            margin-left: 32px;
            background-color: #f6fafd;
            border: 1px solid #e8ecf2;
            border-radius: 4px;
            .logo {
              position: absolute;
              top: 20px;
              left: 21px;
            }
            .name {
              position: absolute;
              top: 20px;
              left: 78px;
              font-size: 14px;
              color: #000;
            }
            .time {
              position: absolute;
              top: 23px;
              right: 19px;
              color: #91a1b7;
            }
            .approval {
              position: absolute;
              bottom: 20px;
              left: 78px;
              color: #606060;
            }
            .reason {
              position: absolute;
              right: 20px;
              bottom: 20px;
              color: #636c78;
            }
          }
        }
      }
    }
  }

  .bottom-contain {
    height: 64px;
    border-top: 1px solid #e8ecf2;
    .button {
      float: right;
      margin: 16px 20px 0 0;
      width: 72px;
      height: 32px;
    }
  }
}

.dialog-apply-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
