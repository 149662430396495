var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trip-summary-container" }, [
    _vm._m(0),
    _vm.isCanEdit
      ? _c("div", [
          _c("div", { staticClass: "date-row" }, [
            _c("span", { staticClass: "title" }, [_vm._v("行程期间")]),
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("el-date-picker", {
                  staticClass: "date-picker",
                  attrs: {
                    type: "daterange",
                    "range-separator": "一",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                  },
                  model: {
                    value: _vm.tripDateRange,
                    callback: function ($$v) {
                      _vm.tripDateRange = $$v
                    },
                    expression: "tripDateRange",
                  },
                }),
                _c("en-icon", {
                  staticClass: "before-icon",
                  style: { fill: "#A9B5C6" },
                  attrs: { name: "jipiaorili", size: "14px" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "reason-row" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("行程事由")]),
              _c("el-input", {
                staticClass: "reason-input",
                class: { wide: !_vm.hasChangeTrip },
                attrs: {
                  type: "textarea",
                  autosize: "",
                  placeholder: "请输入行程事由",
                  maxlength: 500,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.tripData.tripReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.tripData, "tripReason", $$v)
                  },
                  expression: "tripData.tripReason",
                },
              }),
            ],
            1
          ),
          _vm.hasChangeDate
            ? _c(
                "div",
                {
                  staticClass: "date-row",
                  staticStyle: { "margin-top": "16px" },
                },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("期间变更")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "date-picker",
                        attrs: {
                          type: "daterange",
                          "range-separator": "一",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.tripDateRange,
                          callback: function ($$v) {
                            _vm.tripDateRange = $$v
                          },
                          expression: "tripDateRange",
                        },
                      }),
                      _c("en-icon", {
                        staticClass: "before-icon",
                        style: { fill: "#A9B5C6" },
                        attrs: { name: "jipiaorili", size: "14px" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "look-mode-contain" },
          [
            _c(
              "div",
              { staticClass: "line-contain" },
              [
                _c("span", { staticClass: "left-title" }, [_vm._v("行程期间")]),
                _c("span", { staticClass: "content" }, [
                  _vm._v(
                    _vm._s(
                      _vm.tripData.tripStartDate +
                        " / " +
                        _vm.tripData.tripEndDate
                    )
                  ),
                ]),
                _vm.canEidtTime
                  ? _c("en-icon", {
                      staticClass: "edit-time",
                      attrs: { name: "iconbianji1", size: "14px" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeTime.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "line-contain reason-line" }, [
              _c("span", { staticClass: "left-title" }, [_vm._v("行程事由")]),
              _c("span", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.tripData.tripReason)),
              ]),
            ]),
            _vm.timeChangeVisible
              ? _c("timeChangeDialog", {
                  attrs: {
                    "dialog-visible": _vm.timeChangeVisible,
                    mainTime: _vm.tripDelyDateRange,
                  },
                  on: {
                    "update:dialogVisible": function ($event) {
                      _vm.timeChangeVisible = $event
                    },
                    "update:dialog-visible": function ($event) {
                      _vm.timeChangeVisible = $event
                    },
                    updateTime: _vm.updateCTime,
                  },
                })
              : _vm._e(),
            _vm.hasChangeDate
              ? _c(
                  "div",
                  {
                    staticClass: "line-contain",
                    staticStyle: { "padding-top": "16px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "left-title",
                        staticStyle: { "margin-top": "0px" },
                      },
                      [_vm._v("期间变更")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          display: "inline-block",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "date-picker",
                          attrs: {
                            type: "daterange",
                            "range-separator": "一",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.changeDatePicker },
                          model: {
                            value: _vm.tripDelyDateRange,
                            callback: function ($$v) {
                              _vm.tripDelyDateRange = $$v
                            },
                            expression: "tripDelyDateRange",
                          },
                        }),
                        _c("en-icon", {
                          staticClass: "before-icon",
                          style: { fill: "#A9B5C6" },
                          attrs: { name: "jipiaorili", size: "14px" },
                        }),
                        _vm.tripData.delayReason.length > 0
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  "popper-class": "custom-trip-summary-popover",
                                  placement: "bottom-start",
                                  trigger: "hover",
                                  disabled: false,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tips-trip-change-popover" },
                                  _vm._l(
                                    _vm.tripData.delayReason,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "content-contain",
                                        },
                                        [
                                          _c("div", { staticClass: "dian" }),
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.modifyTime) +
                                                " "
                                            ),
                                          ]),
                                          _c("div", { staticClass: "text" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.changeReason) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c("en-icon", {
                                  staticClass: "tips-icon",
                                  style: { fill: "#F7BF27" },
                                  attrs: {
                                    slot: "reference",
                                    name: "xiaoxi",
                                    size: "12px",
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-row" }, [
      _c("span", { staticClass: "title" }, [_vm._v("行程概要")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }