var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _c(
        "div",
        { staticClass: "user-contain" },
        [
          _vm._l(_vm.dataList, function (item) {
            return _c(
              "div",
              { key: item.key, staticClass: "user-detail-contain" },
              [
                _c("en-user-logo", {
                  staticClass: "user-icon",
                  attrs: { "user-name": item.name, size: "30" },
                }),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.deleteUser(item)
                      },
                    },
                  },
                  [
                    _c("en-icon", {
                      staticClass: "user-delete",
                      style: { fill: "#F76B6B" },
                      attrs: { name: "shibai", size: "14px" },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "user-detail-contain",
              staticStyle: { width: "32px" },
            },
            [
              _c(
                "div",
                { staticClass: "add-contain", on: { click: _vm.addUserClick } },
                [
                  _c("en-icon", {
                    staticClass: "add-icon",
                    style: { fill: "#3e90fe" },
                    attrs: { name: "tianjia-anniutoubu", size: "10px" },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
      _vm.contactsVisible
        ? _c("enContacts", {
            attrs: {
              "dialog-visible": _vm.contactsVisible,
              "edit-contact": _vm.editContact,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.contactsVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.contactsVisible = $event
              },
              getEnContactConfirm: _vm.getSelectContact,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }