<template>
  <en-dialog title="期间变更" :visible.sync="dialogVisible" width="700px" @close="close">
    <div class="cents">
      <span class="left-title">变更时间</span>
      <div class="inner-cent">
        <el-date-picker
          v-model="changeTime"
          class="date-picker"
          type="daterange"
          range-separator="一"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <en-icon name="jipiaorili" size="14px" :style="{ fill: '#A9B5C6' }" class="before-icon"></en-icon>
      </div>
    </div>
    <!-- <el-popover v-if="tripData.delayReason.length > 0" popper-class="custom-trip-summary-popover" placement="bottom-start" trigger="hover" :disabled="false">
          <div class="tips-trip-change-popover">
            <div v-for="item in tripData.delayReason" :key="item.id" class="content-contain">
              <div class="dian"></div>
              <div class="time">
                {{ `${delayPrefix(item)}${item.delayDate}:` }}
              </div>
              <div class="text">
                {{ `${item.changeReason}` }}
              </div>
            </div>
          </div>
          <en-icon slot="reference" name="xiaoxi" size="12px" :style="{ fill: '#F7BF27' }" class="tips-icon"></en-icon>
        </el-popover> -->
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm()">确定</en-button>
    </span>
  </en-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "timeChangeDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    mainTime: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogTripChangeVisible: true,
      title: "行程期间变更事由",
      placeholder: "请输入100字以内变更事由",
      inputText: "",
      changeTime: []
    };
  },
  watch: {
  },
  mounted() {
    this.changeTime = this.mainTime;
    if (this.dialogType === "tripChange") {
      this.title = "行程期间变更事由";
      this.placeholder = "请输入100字以内变更事由";
    } else if (this.dialogType === "tripDelay") {
      this.title = "行程延期事由";
      this.placeholder = "请输入100字以内延期事由";
    }
  },
  methods: {
    close() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm() {
      const arr = [dayjs(this.changeTime[0]).format("YYYY-MM-DD"), dayjs(this.changeTime[1]).format("YYYY-MM-DD")];
      this.$emit("updateTime", arr);
    },
    confirmClick() {
      if (this.isMust && this.inputText.length === 0) {
        this.$message.error("输入事由不能为空!");
        return;
      }
      this.dialogTripChangeVisible = false;
      const data = {
        type: this.dialogType,
        value: this.inputText
      };
      this.$emit("getTripChangeValue", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.left-title {
  width: 70px;
  flex: none;
  line-height: 32px;
}
.cents {
  min-height: 100px;
  display: flex;
}
.inner-cent {
  display: inline-block;
  position: relative;
  margin-left: 10px;
}
.date-picker {
  height: 32px;
  width: 580px;
  & /deep/ .el-range-input {
    text-align: left;
    padding-left: 2px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
  }
  & /deep/ .el-range-separator {
    line-height: 24px !important;
    margin-top: 2px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #636c78;
    width: 30px;
  }
  & /deep/ .el-range__icon {
    display: none;
  }
  & /deep/ .el-range__close-icon {
    line-height: 32px !important;
    padding-left: 80px;
  }
}
/deep/ .el-input__inner {
  padding-left: 32px;
}
.after-icon {
  position: absolute;
  top: 9px;
  right: 10px;
}
.before-icon {
  position: absolute;
  top: 8px;
  left: 10px;
}
// .body-contain {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);

//   width: 700px;
//   height: 314px;
//   background-color: #fff;
//   border-radius: 6px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

//   .title {
//     position: absolute;
//     left: 20px;
//     top: 20px;
//     height: 14px;
//     font-size: 14px;
//     line-height: 14px;
//     font-family: MicrosoftYaHei;
//     font-weight: bold;
//     text-align: left;
//     color: rgba(51, 51, 51, 1);
//   }
//   .close-icon {
//     position: absolute;
//     right: 20px;
//     top: 20px;
//     width: 14px;
//     height: 14px;
//   }
//   .content-contain {
//     margin: 52px 20px 16px 20px;
//     width: 660px;
//     height: 200px;
//     .text-area {
//       &/deep/ .el-textarea__inner {
//         padding: 5px 10px;
//         height: 200px;
//         background-color: #f5f7fa;
//         border: 1px solid #e8ecf2;
//       }
//       &/deep/ .el-input__count {
//         background-color: #f5f7fa;
//       }
//     }
//   }
//   .confirm-button {
//     position: absolute;
//     right: 20px;
//     bottom: 20px;
//     width: 72px;
//     height: 32px;
//   }
// }
// .dialog-contain {
//   & /deep/ .el-dialog__header {
//     padding: 0;
//     margin: 0;
//     height: 0;
//   }
//   & /deep/ .el-dialog__body {
//     padding: 0;
//     margin: 0;
//   }
// }
</style>
