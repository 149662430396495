<template>
  <div class="dialog-contain">
    <el-dialog title="" :show-close="false" :visible.sync="dialogNodeVisible">
      <div class="body-contain">
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ fill: '#A9B5C6' }"
          ></en-icon>
        </div>

        <div class="content-contain">
          <div
            v-for="item in dataList"
            :key="item.name"
            class="node-contain"
            @click="nodeClick(item)"
          >
            <!-- :style="{ backgroundColor: item.color }"-->
            <div class="icon-contain" >
              <en-icon class="icon" :name="item.icon"></en-icon>
            </div>
            <div class="node-text">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EnTripNode",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogNodeVisible: true,
      title: "新增节点",
      dataList: [
        {
          id: "flight",
          name: "机票",
          icon: "jipiao",
          color: "#50BFF0"
        },
        {
          id: "hotel",
          name: "酒店",
          icon: "jiudian",
          color: "#F6BE3B"
        },
        {
          id: "car",
          name: "租车",
          icon: "yongche",
          color: "#5DCA81"
        },
        {
          id: "supplement",
          name: "补单",
          icon: "budan",
          color: "#D59E7C"
        }
      ]
    };
  },
  watch: {
    dialogNodeVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  methods: {
    closeClick() {
      this.dialogNodeVisible = false;
    },
    nodeClick(item) {
      this.dialogNodeVisible = false;
      this.$emit("selectaddTripNode", item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 900px;
  height: 403px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    margin: 110px 45px auto 45px;
    height: 183px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    .node-contain {
      position: relative;
      width: 180px;
      height: 183px;
      border-radius: 6px;
      text-align: center;
      border: 1px solid #fff;
      .icon-contain {
        position: relative;
        width: 178px;
        height: 158px;
        border-radius: 4px;
        border: 1px solid #E8ECF2;
        .icon {
          width: 110px;
          height: 106px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:hover {
        .icon-contain {
          background-color: #f5f9fd;
        }
      }
      .node-text {
        margin-top: 10px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #333;
      }
    }
  }
}

.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
