var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogNodeVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogNodeVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "body-contain" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c(
              "div",
              { on: { click: _vm.closeClick } },
              [
                _c("en-icon", {
                  staticClass: "close-icon",
                  style: { fill: "#A9B5C6" },
                  attrs: { name: "guanbi-danchuang", size: "14px" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content-contain" },
              _vm._l(_vm.dataList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.name,
                    staticClass: "node-contain",
                    on: {
                      click: function ($event) {
                        return _vm.nodeClick(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-contain" },
                      [
                        _c("en-icon", {
                          staticClass: "icon",
                          attrs: { name: item.icon },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "node-text" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }