<template>
  <div class="car-container" style="overflow-y: auto;">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
    >
      <!-- 时间地点 -->
      <sectionTitle section-name="时间地点" />

      <!-- 出发日期  -->
      <el-form-item prop="arrivalDate">
        <div style="position: relative;">
          <el-date-picker
            v-model="form.arrivalDate"
            type="date"
            placeholder="出发日期"
          >
          </el-date-picker>
          <en-icon
            name="riqi-shanglvguanli"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <!-- 出发地  -->
      <el-form-item prop="startCity">
        <el-input v-model="form.startCity" clearable placeholder="出发地">
          <en-icon
            slot="prefix"
            name="chufachengshi-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <!-- 目的地 -->
      <el-form-item prop="arrivalCity">
        <el-input v-model="form.arrivalCity" clearable placeholder="目的地">
          <en-icon
            slot="prefix"
            name="daodachengshi-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>
      <separatorLine />

      <!-- 人员信息 -->
      <sectionTitle section-name="乘车人员" />
      <userList :user-list.sync="form.userList" />
      <separatorLine />

      <!-- 合计金额 -->
      <sectionTitle section-name="合计金额" />
      <el-form-item prop="totalAmount">
        <el-input
          v-model="form.totalAmount"
          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
          clearable placeholder="合计金额"
          maxlength="10"
        >
          <en-icon
            slot="prefix"
            name="hejijine-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <separatorLine />

      <!-- 事项说明 -->
      <sectionTitle section-name="事项说明" />
      <el-form-item prop="explain">
        <div style="position: relative;">
          <el-input
            v-model="form.explain"
            type="textarea"
            placeholder="事项说明"
            maxlength="100"
          >
          </el-input>
          <en-icon
            name="shixiangshuoming-budan"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Store from "@/store";
import separatorLine from "../components/separator-line";
import sectionTitle from "../components/section-title";
import userList from "../components/user-list";

export default {
  name: "Car",
  components: {
    separatorLine,
    sectionTitle,
    userList
  },
  props: {
    carData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        type: "003",
        code: "",
        classType: "000",
        arrivalDate: "",
        startCity: "",
        arrivalCity: "",
        totalAmount: "",
        explain: "",
        userId: "",
        userList: [

        ]
      },
      rules: {
        // 添加验证
        arrivalDate: [
          {
            required: true,
            message: "请选择出发日期",
            trigger: "blur"
          }
        ],
        startCity: [
          {
            required: true,
            message: "输入出发地",
            trigger: "blur"
          }
        ],
        arrivalCity: [
          {
            required: true,
            message: "输入目的地",
            trigger: "blur"
          }
        ],
        totalAmount: [
          {
            required: true,
            message: "输入合计金额",
            trigger: "blur"
          }
        ],
        explain: [
          {
            required: true,
            message: "输入事项说明",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    if (this.carData) {
      this.form = this.carData;
    } else {
      this.form.userList.push({
        userId: Store.getters.userInfo.userId,
        name: Store.getters.userInfo.name
      });
    }
  },
  beforeDestroy() {
    this.$emit("update:carData", this.form);
  },
  methods: {
    parentHandleClick(val) {
      console.log(val);
      /* eslint-disable */
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.userList?.length === 0) {
            this.$alert("请添加人员");
          } else {
            this.$emit("commitData", this.form);
          }
        } else {
          this.$alert("请根据提示完成对应内容的填写或选择");
          return false;
        }
      });
      /* eslint-enable */
    }
  }
};
</script>

<style lang="scss" scoped>
.car-container {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  .custom-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .en-prefix {
    margin-top: 7px;
    margin-right: 6px;
    fill: #a9b5c6;
  }
  & /deep/ .el-input,
  .el-select {
    margin-bottom: 14px;
  }
  & /deep/ .el-form-item {
    min-height: 46px;
    margin-left: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .el-form-item__label {
      display: none;
    }
    .el-input {
      margin-bottom: 14px;
    }
  }
  & /deep/ .el-input__inner,
  .el-textarea__inner {
    width: 820px;
    padding-left: 12px;
  }
  & /deep/ .el-input__icon {
    line-height: 32px !important;
  }
  & /deep/ .el-date-editor {
    width: 820px;
    .el-input__prefix {
      display: none;
    }
  }
  & /deep/ .el-date-editor > .el-input__prefix {
    left: 6px !important;
  }
  & /deep/ .el-input__inner {
    padding-left: 34px;
  }
  & /deep/ .el-textarea {
    margin-bottom: 20px;
    width: 820px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #b9c4d2;
    .el-textarea__inner {
      padding-left: 34px;
    }
  }
}
</style>
