var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trip-detail-contain" },
    [
      _vm.amountEditVisible
        ? _c("tripInputDialog", {
            attrs: {
              title: "补助金额",
              "init-value": _vm.tripDetailData.subsidyAmount,
              "dialog-visible": _vm.amountEditVisible,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.amountEditVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.amountEditVisible = $event
              },
              getInputDialogConfirm: _vm.getInputConfirmValue,
            },
          })
        : _vm._e(),
      _vm.tripNodeShow
        ? _c("en-trip-node", {
            attrs: { "dialog-visible": _vm.tripNodeShow },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.tripNodeShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.tripNodeShow = $event
              },
              selectaddTripNode: _vm.selectAddTripNode,
            },
          })
        : _vm._e(),
      _vm.chooseItineraryShow
        ? _c("en-itinerary", {
            attrs: {
              "dialog-visible": _vm.chooseItineraryShow,
              "trip-data-list": _vm.transferableTravelList,
              "only-choose-itinerary": true,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.chooseItineraryShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.chooseItineraryShow = $event
              },
              selectTripData: _vm.selectTripData,
            },
          })
        : _vm._e(),
      _c("en-tips-dialog", {
        attrs: {
          "dialog-visible": _vm.deleteTipsDialogVisible,
          title: "温馨提醒:",
          message: _vm.deleteTipsMessage,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.deleteTipsDialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.deleteTipsDialogVisible = $event
          },
          getEnTipsDialogConfirm: _vm.confirmDeleteTipsDialog,
        },
      }),
      _vm.enConfirmDialogShow
        ? _c("en-confirm-dialog", {
            attrs: {
              "dialog-visible": _vm.enConfirmDialogShow,
              "show-cancel": true,
              "tips-text": "当前行程已不存在有效节点，是否删除",
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.enConfirmDialogShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.enConfirmDialogShow = $event
              },
              getComfirmValue: _vm.getEnComfirmValue,
            },
          })
        : _vm._e(),
      _vm.tripOccupyShow
        ? _c("tripOccupy", {
            attrs: { "dialog-visible": _vm.tripOccupyShow },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.tripOccupyShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.tripOccupyShow = $event
              },
            },
          })
        : _vm._e(),
      _vm.nodeDetailShow
        ? _c("nodeDetail", {
            attrs: {
              "dialog-visible": _vm.nodeDetailShow,
              "all-node-list": _vm.allDetailNodeList(),
              "current-node": _vm.nodeTempClickRow,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.nodeDetailShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.nodeDetailShow = $event
              },
            },
          })
        : _vm._e(),
      _vm.applyShow
        ? _c("apply", {
            attrs: { "dialog-visible": _vm.applyShow },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.applyShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.applyShow = $event
              },
            },
          })
        : _vm._e(),
      _vm.tripMemoDialogShow
        ? _c("tripMemoDialog", {
            attrs: {
              "dialog-visible": _vm.tripMemoDialogShow,
              "dialog-type": _vm.tripMemoDialogType,
              "is-must": _vm.tripMemoDialogMust,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.tripMemoDialogShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.tripMemoDialogShow = $event
              },
              getTripChangeValue: _vm.getTripDialogValue,
            },
          })
        : _vm._e(),
      _vm.supplementShow
        ? _c("supplement", {
            attrs: {
              "origin-data": _vm.nodeTempData,
              "dialog-visible": _vm.supplementShow,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.supplementShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.supplementShow = $event
              },
              addNewTripNode: _vm.addNewTripNode,
              updateTripNode: _vm.updateTripNode,
            },
          })
        : _vm._e(),
      _vm.overStandardVisible
        ? _c("overStandardDialog", {
            attrs: { "dialog-visible": _vm.overStandardVisible },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.overStandardVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.overStandardVisible = $event
              },
              getOverStandardMemo: _vm.getOverStandardMemo,
            },
          })
        : _vm._e(),
      _vm.addChangeReasonVisible
        ? _c("addChangeReason", {
            attrs: { "dialog-visible": _vm.addChangeReasonVisible },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.addChangeReasonVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.addChangeReasonVisible = $event
              },
              getChangeReason: _vm.getChangeReason,
            },
          })
        : _vm._e(),
      _c("en-tips-dialog", {
        attrs: {
          "dialog-visible": _vm.reasonTipsDialogVisible,
          title: "温馨提醒:",
          message: _vm.reasonTipsMessage,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.reasonTipsDialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.reasonTipsDialogVisible = $event
          },
          getEnTipsDialogConfirm: _vm.confirmReasonTipsDialog,
        },
      }),
      _c(
        "div",
        {
          staticClass: "content-main-contain",
          style:
            _vm.isShowBottomOperationButton() || _vm.flowBtnList.length
              ? "height: calc(100% - 64px)"
              : "",
        },
        [
          _c(
            "div",
            { staticClass: "top-contain" },
            [
              _c("div", { staticClass: "top-bar" }, [
                _c(
                  "div",
                  { staticClass: "left-contain", on: { click: _vm.backClick } },
                  [
                    _c("en-icon", {
                      staticClass: "back-icon",
                      style: { fill: "#A9B5C6" },
                      attrs: { name: "fanhui", size: "14px;" },
                    }),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.tripDetailData.tripNo
                            ? _vm.tripDetailData.tripNo
                            : "行程单"
                        )
                      ),
                    ]),
                    _vm.tripDetailData.tripStatus
                      ? _c("en-trip-bill-status", {
                          staticClass: "status",
                          attrs: {
                            "is-dark": true,
                            status: Number(_vm.tripDetailData.tripStatus),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right-contain" },
                  _vm._l(_vm.rightButtonList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.key,
                        staticStyle: {
                          display: "block",
                          "margin-left": "18px",
                        },
                      },
                      [
                        _c(
                          "en-button",
                          {
                            attrs: {
                              type: "text",
                              "icon-color": "red",
                              icon: item.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.topRightBtnClick(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _c("tripSummery", {
                attrs: { "trip-data": _vm.tripDetailData },
                on: { hasChangeDelyDate: _vm.hasChangeDely },
              }),
            ],
            1
          ),
          _vm._m(0),
          _c("div", { staticClass: "bottom-contain" }, [
            _c(
              "div",
              { staticClass: "bottom-bar" },
              [
                _c("span", { staticClass: "title" }, [_vm._v("行程节点")]),
                _vm.addButton
                  ? _c("en-icon", {
                      staticClass: "icon",
                      staticStyle: { cursor: "pointer" },
                      attrs: { size: "18", name: "icontianjia" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.addNodeClick.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "node-contain",
                class: { noNode: _vm.noNodeMode },
              },
              [
                !_vm.noNodeMode
                  ? _c("div", { staticClass: "left-line" })
                  : _vm._e(),
                !_vm.noNodeMode
                  ? _c("div", { staticClass: "right-line" })
                  : _vm._e(),
                !_vm.noNodeMode
                  ? _c("div", { staticClass: "bottom-line" })
                  : _vm._e(),
                _vm.noNodeMode
                  ? _c("div", { staticClass: "no-node-contain" }, [
                      _c("img", {
                        staticClass: "no-node-img",
                        attrs: { src: require("./data/trip_no_node.png") },
                      }),
                      _c("div", { staticClass: "no-node-text" }, [
                        _vm._v(" 点击添加节点 "),
                      ]),
                    ])
                  : _vm._l(
                      _vm.tripDetailData.tripNodeList,
                      function (nodeItem) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: nodeItem.tripList.length !== 0,
                                expression: "nodeItem.tripList.length !== 0",
                              },
                            ],
                            key: nodeItem.key,
                            staticClass: "node-section-contain",
                          },
                          [
                            _c("div", { staticClass: "section-top" }, [
                              _vm._v(" " + _vm._s(nodeItem.tripDate) + " "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "node-detail-contain" },
                              _vm._l(nodeItem.tripList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.key,
                                    staticClass: "node-detail",
                                    on: {
                                      click: function ($event) {
                                        return _vm.nodeCellClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "node-detail-content",
                                        style:
                                          Number(item.status) === 7
                                            ? "opacity: 0.5;"
                                            : "opacity: 1;",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "node-first-contain" },
                                          [
                                            item.tripNodeType === "000" ||
                                            (item.tripNodeType === "002" &&
                                              item.type === "000")
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "icon-contain",
                                                  },
                                                  [
                                                    _c("en-icon", {
                                                      style: {
                                                        fill: "#49BEF2",
                                                      },
                                                      attrs: {
                                                        size: "40px",
                                                        name: _vm.tripNodeTypeIconName(
                                                          item
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "icon-contain icon-contain-bg",
                                                    class: {
                                                      hotel:
                                                        item.tripNodeType ===
                                                          "001" ||
                                                        (item.tripNodeType ===
                                                          "002" &&
                                                          item.type === "001"),
                                                      train:
                                                        item.tripNodeType ===
                                                          "004" ||
                                                        (item.tripNodeType ===
                                                          "002" &&
                                                          item.type === "002"),
                                                      car:
                                                        item.tripNodeType ===
                                                          "003" ||
                                                        (item.tripNodeType ===
                                                          "002" &&
                                                          item.type === "003"),
                                                      other:
                                                        item.tripNodeType ===
                                                          "002" &&
                                                        item.type === "004",
                                                    },
                                                  },
                                                  [
                                                    _c("en-icon", {
                                                      style: { fill: "#fff" },
                                                      attrs: {
                                                        size: "20px",
                                                        name: _vm.tripNodeTypeIconName(
                                                          item
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                            _c(
                                              "div",
                                              { staticClass: "explain" },
                                              [
                                                _c("en-div-hover", {
                                                  staticClass: "text",
                                                  attrs: {
                                                    detail:
                                                      _vm.tripNodeName(item),
                                                  },
                                                }),
                                                item.standardFlag === "001"
                                                  ? _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          "popper-class":
                                                            "custom-detail-popover",
                                                          placement:
                                                            "bottom-start",
                                                          trigger: "hover",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tips-popover",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.standardMemo
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c("en-icon", {
                                                          staticClass: "icon",
                                                          style: {
                                                            fill: "#F76B6B",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            name: "chaobiaoshiyou-wodehangcheng",
                                                            size: "16px",
                                                          },
                                                          slot: "reference",
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.tripNodeType === "002"
                                                  ? _c("en-icon", {
                                                      staticClass: "icon",
                                                      style: {
                                                        fill: "#3e90fe",
                                                      },
                                                      attrs: {
                                                        slot: "reference",
                                                        name: "budan-wodehangcheng",
                                                        size: "18px",
                                                      },
                                                      slot: "reference",
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "time" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.tripDateTime(item)) +
                                              " "
                                          ),
                                        ]),
                                        _c("div", { staticClass: "price" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s("¥" + item.totalAmount) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("en-trip-node-status", {
                                          attrs: {
                                            status: Number(item.status),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "operation",
                                        style:
                                          _vm.operationButton(item).length > 0
                                            ? ""
                                            : "visibility: hidden;",
                                      },
                                      _vm._l(
                                        _vm.operationButton(item),
                                        function (obj) {
                                          return _c(
                                            "div",
                                            {
                                              key: obj.id,
                                              staticClass: "operation-button",
                                              attrs: {
                                                onClick:
                                                  "event.cancelBubble = true",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.operationButtonClick(
                                                    item,
                                                    obj
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("en-icon", {
                                                style: { fill: obj.color },
                                                attrs: { name: obj.icon },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c("div", {
                                      staticClass: "separator",
                                      style:
                                        nodeItem.tripList.indexOf(item) ===
                                        nodeItem.tripList.length - 1
                                          ? "visibility:hidden;"
                                          : "",
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                !_vm.noNodeMode
                  ? _c("div", { staticClass: "node-bottom-contain" }, [
                      _c("div", { staticClass: "top-space" }),
                      _vm._m(1),
                      !_vm.noNodeMode
                        ? _c(
                            "div",
                            { staticClass: "amount-detail" },
                            [
                              _vm.subsidyMoney
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "30px",
                                        height: "35px",
                                        display: "inline-block",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.editSubsidy
                                            ? (_vm.amountEditVisible = true)
                                            : ""
                                        },
                                      },
                                    },
                                    [
                                      _c("en-icon", {
                                        staticClass: "edit",
                                        style: { fill: "#3e90fe" },
                                        attrs: {
                                          name: "bianji-kuaijiecaozuo",
                                          size: "18px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.subsidyMoney
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        "popper-class": "custom-detail-popover",
                                        placement: "bottom-start",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "amount-popover" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "content" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tripBottomDetail())
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "content" },
                                            [
                                              _vm._v(
                                                "总计金额：" +
                                                  _vm._s(
                                                    _vm.tripDetailData
                                                      .tripTotalAmount
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "content" },
                                            [
                                              _vm._v(
                                                "企业支付：" +
                                                  _vm._s(
                                                    _vm.tripDetailData
                                                      .hasBalance
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "content" },
                                            [
                                              _vm._v(
                                                "补助金额：" +
                                                  _vm._s(
                                                    _vm.tripDetailData
                                                      .subsidyAmount
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: {
                                              "background-color": "#E8ECF2",
                                              height: "1px",
                                              margin: "1px 0",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "last-content" },
                                            [_vm._v("报销金额：")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "last-amount" },
                                            [
                                              _vm._v(
                                                "¥" +
                                                  _vm._s(
                                                    _vm.tripDetailData
                                                      .expenseAmount
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            height: "12px",
                                            "margin-top": "2px",
                                            display: "inline-block",
                                          },
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "#333" } },
                                            [_vm._v("补助金额：")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#F78528" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tripDetailData
                                                    .subsidyAmount
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#333",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("报销金额：")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#636C78" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tripDetailData
                                                    .expenseAmount
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "node-total-contain" }, [
                        _c("span", { staticStyle: { color: "#636C78" } }, [
                          _vm._v("总价："),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#F78528",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("¥ ")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#F78528",
                              "font-size": "16px",
                              "font-weight": "500",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.tripDetailData.tripTotalAmount > 0
                                  ? _vm.tripDetailData.tripTotalAmount
                                  : "0.00"
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm.isHasShareUserList
              ? _c(
                  "div",
                  { staticClass: "share-contain" },
                  [
                    _c("en-icon", {
                      staticClass: "gongxiang-icon",
                      style: { fill: "#ffad2c" },
                      attrs: { size: "12px", name: "tishi" },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.shareUserDetail)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            false
              ? _c(
                  "div",
                  {
                    staticClass: "operation-contain",
                    style:
                      _vm.deleteButton() ||
                      _vm.saveButton ||
                      _vm.submitButton ||
                      _vm.baoXiaoButton ||
                      _vm.jieShuButton ||
                      _vm.endButton
                        ? "display:flex;"
                        : "display:none;",
                  },
                  [
                    _vm.deleteButton()
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleDeleteButton },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e(),
                    _vm.saveButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "green" },
                            on: { click: _vm.handleSaveButton },
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e(),
                    _vm.submitButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "blue" },
                            on: { click: _vm.handleSubmitButton },
                          },
                          [_vm._v(" 提交 ")]
                        )
                      : _vm._e(),
                    _vm.baoXiaoButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "blue" },
                            on: { click: _vm.handleBaoXiaoButton },
                          },
                          [_vm._v(" 报销 ")]
                        )
                      : _vm._e(),
                    _vm.jieShuButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleJieShuButton },
                          },
                          [_vm._v(" 结束 ")]
                        )
                      : _vm._e(),
                    _vm.endButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleEndButton },
                          },
                          [_vm._v(" 终止 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.isShowFlow
            ? _c("div", { staticClass: "seaprator-contain" }, [
                _c("div", { staticClass: "seaprator-top" }),
                _c("div", { staticClass: "seaprator-bottom" }),
              ])
            : _vm._e(),
          _vm.isShowFlow
            ? _c("div", { staticClass: "flow-contain" }, [
                _c("div", { staticClass: "flow-title" }, [
                  _vm._v(" 查看流程 "),
                  _c(
                    "div",
                    {
                      staticClass: "flow-title-right",
                      on: {
                        click: function ($event) {
                          _vm.isExpose = !_vm.isExpose
                        },
                      },
                    },
                    [
                      _vm.mdDetailData.mdInfo
                        ? _c("div", { staticClass: "status" }, [
                            _vm._v(_vm._s(_vm.mdDetailData.mdInfo.nodeName)),
                          ])
                        : _vm._e(),
                      _c("en-icon", {
                        staticClass: "icon",
                        class: { expose: _vm.isExpose },
                        attrs: { name: "zhankai", size: "14px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.isExpose
                  ? _c(
                      "div",
                      { staticClass: "flow-list-contain" },
                      [
                        _c("cardList", {
                          attrs: {
                            data: _vm.mdDetailData,
                            tripDataId: _vm.tripDetailData.businessKey,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.isShowFlow
            ? _c("div", { staticClass: "seaprator-contain" }, [
                _c("div", { staticClass: "seaprator-top" }),
                _c("div", { staticClass: "seaprator-bottom" }),
              ])
            : _vm._e(),
        ]
      ),
      _vm.isShowBottomOperationButton() || _vm.flowBtnList.length
        ? _c("div", { staticClass: "operation-contain" }, [
            _vm.isShowBottomOperationButton()
              ? _c(
                  "div",
                  {
                    class: { expose: _vm.isExpose },
                    staticStyle: { dispaly: "inline" },
                  },
                  [
                    _vm.deleteButton()
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleDeleteButton },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e(),
                    _vm.saveButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "green" },
                            on: { click: _vm.handleSaveButton },
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e(),
                    _vm.submitButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "blue" },
                            on: { click: _vm.handleSubmitButton },
                          },
                          [_vm._v(" 提交 ")]
                        )
                      : _vm._e(),
                    _vm.baoXiaoButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "blue" },
                            on: { click: _vm.handleBaoXiaoButton },
                          },
                          [_vm._v(" 报销 ")]
                        )
                      : _vm._e(),
                    _vm.jieShuButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleJieShuButton },
                          },
                          [_vm._v(" 结束 ")]
                        )
                      : _vm._e(),
                    _vm.endButton
                      ? _c(
                          "en-button",
                          {
                            staticClass: "operation-button",
                            attrs: { type: "red" },
                            on: { click: _vm.handleEndButton },
                          },
                          [_vm._v(" 终止 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.flowBtnList.length
              ? _c(
                  "div",
                  { staticStyle: { dispaly: "inline" } },
                  _vm._l(_vm.flowBtnList, function (btn, key) {
                    return _c(
                      "span",
                      { key: key },
                      [
                        btn.btnKey === "agree"
                          ? _c(
                              "en-button",
                              {
                                attrs: { type: "blue" },
                                on: {
                                  click: function ($event) {
                                    return _vm.btnHandle(btn)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(btn.btnName) + " ")]
                            )
                          : _vm._e(),
                        btn.btnKey === "propose" ||
                        btn.btnKey === "proposeTo" ||
                        btn.btnKey === "signed"
                          ? _c(
                              "en-button",
                              {
                                attrs: { type: "green" },
                                on: {
                                  click: function ($event) {
                                    return _vm.btnHandle(btn)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(btn.btnName) + " ")]
                            )
                          : _vm._e(),
                        btn.btnKey === "reject" ||
                        btn.btnKey === "rebut" ||
                        btn.btnKey === "stop" ||
                        btn.btnKey === "getback"
                          ? _c(
                              "en-button",
                              {
                                attrs: { type: "red" },
                                on: {
                                  click: function ($event) {
                                    return _vm.btnHandle(btn)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(btn.btnName) + " ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.businessDialog
        ? _c("businessDialog", {
            attrs: {
              businessParams: _vm.businessParams,
              dialogVisible: _vm.businessDialog,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.businessDialog = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.businessDialog = $event
              },
              submitSuccess: _vm.submitSuccess,
            },
          })
        : _vm._e(),
      _c(
        "en-dialog",
        {
          attrs: {
            title: "选择共享人",
            visible: _vm.personDilogShow,
            width: "900px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.personDilogShow = false
            },
          },
        },
        [
          _c("en-transfer-data", {
            attrs: { config: _vm.shareTransferConfig },
            model: {
              value: _vm.sharePersonList,
              callback: function ($$v) {
                _vm.sharePersonList = $$v
              },
              expression: "sharePersonList",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitSelect } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("businessFunction", {
        ref: "businessFunction",
        attrs: {
          sourceType: "toDo",
          id: _vm.btnObj.id,
          templateId: _vm.btnObj.templateId,
          "p-id": _vm.btnObj.pId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "seaprator-contain" }, [
      _c("div", { staticClass: "seaprator-top" }),
      _c("div", { staticClass: "seaprator-bottom" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dashed" }, [
      _c("div", { staticClass: "dashed-line" }),
      _c("div", { staticClass: "dashed-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }