<template>
  <div class="other-container" style="overflow-y: auto;">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
    >
      <!-- 事项日期  -->
      <sectionTitle section-name="事项日期" />
      <el-form-item prop="arrivalDate">
        <div style="position: relative;">
          <el-date-picker
            v-model="form.arrivalDate"
            type="date"
            placeholder="事项日期"
          >
          </el-date-picker>
          <en-icon
            name="riqi-shanglvguanli"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <separatorLine />

      <!-- 人员信息(暂时屏蔽,老接口不需要)
      <sectionTitle section-name="事项人员" />
      <userList :user-list.sync="userList" />
      <separatorLine />
      -->

      <!-- 合计金额 -->
      <sectionTitle section-name="合计金额" />
      <el-form-item prop="totalAmount">
        <el-input
          v-model="form.totalAmount"
          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
          clearable
          placeholder="合计金额"
          class="amount-input"
          maxlength="10"
        >
          <en-icon
            slot="prefix"
            name="hejijine-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <separatorLine />

      <!-- 事项说明 -->
      <sectionTitle section-name="事项说明" />
      <el-form-item prop="explain">
        <div style="position: relative;">
          <el-input
            v-model="form.explain"
            type="textarea"
            placeholder="事项说明"
            maxlength="100"
          >
          </el-input>
          <en-icon
            name="shixiangshuoming-budan"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import separatorLine from "../components/separator-line";
import sectionTitle from "../components/section-title";
// import userList from "../components/user-list";

export default {
  name: "Other",
  components: {
    separatorLine,
    sectionTitle
    // userList
  },
  props: {
    otherData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // form数据
      form: {
        type: "004",
        code: "",
        classType: "000",
        arrivalDate: "",
        startCity: "",
        arrivalCity: "",
        totalAmount: "",
        explain: "",
        userId: ""
      },
      clickUser: null,
      rules: {
        // 添加验证
        otherDate: [
          {
            required: true,
            message: "请选择事项日期",
            trigger: "blur"
          }
        ],
        totalAmount: [
          {
            required: true,
            message: "输入合计金额",
            trigger: "blur"
          }
        ],
        explain: [
          {
            required: true,
            message: "输入事项说明",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    if (this.otherData) {
      this.form = this.otherData;
    }
  },
  beforeDestroy() {
    this.$emit("update:otherData", this.form);
  },
  methods: {
    parentHandleClick(val) {
      console.log(val);
      /* eslint-disable */
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.userList?.length === 0) {
          //   this.$alert("请添加人员");
          // } else {
          //   this.$emit("commitData", this.form);
          // }
          this.$emit("commitData", this.form);
        } else {
          this.$alert("请根据提示完成对应内容的填写或选择");
          return false;
        }
      });
      /* eslint-enable */
    }
  }
};
</script>

<style lang="scss" scoped>
.other-container {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  .form-section {
    margin: 19px 20px 14px 21px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #333;
  }
  .custom-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .en-prefix {
    margin-top: 7px;
    margin-right: 6px;
    fill: #a9b5c6;
  }
  //单独解决金额输入框,清空图标位置问题
  .amount-input /deep/ .el-icon-circle-close {
    padding-right: 35px;
  }
  & /deep/ .el-input,
  .el-select {
    margin-bottom: 14px;
  }
  & /deep/ .el-form-item {
    min-height: 46px;
    margin-left: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .el-form-item__label {
      display: none;
    }
    .el-input {
      margin-bottom: 14px;
    }
  }
  & /deep/ .el-input__inner,
  .el-textarea__inner {
    width: 820px;
    padding-left: 12px;
  }
  & /deep/ .el-input__icon {
    line-height: 32px !important;
  }
  & /deep/ .el-date-editor {
    width: 820px;
    .el-input__prefix {
      display: none;
    }
  }
  & /deep/ .el-date-editor > .el-input__prefix {
    left: 6px !important;
  }
  & /deep/ .el-input__inner {
    padding-left: 34px;
  }
  & /deep/ .el-input__suffix {
    padding-left: 34px;
  }
  & /deep/ .el-textarea {
    margin-bottom: 20px;
    width: 820px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #b9c4d2;
    .el-textarea__inner {
      padding-left: 34px;
    }
  }
}
</style>
