<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogTripChangeVisible"
    >
      <div class="body-contain">
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>

        <div class="content-contain">
          <el-input
            v-model="inputText"
            class="text-area"
            type="textarea"
            resize="none"
            maxlength="100"
            show-word-limit
            :placeholder="placeholder"
          ></el-input>
        </div>
        <en-button
          class="confirm-button"
          @click="confirmClick"
        >
          确定
        </en-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EnTripChangeDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    // 弹框事由类型
    dialogType: {
      type: String,
      default: "tripChange"
      // tripChange:行程期间变更事由
      // tripDelay:行程延期事由
    },
    // 是否必填校验
    isMust: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogTripChangeVisible: true,
      title: "行程期间变更事由",
      placeholder: "请输入100字以内变更事由",
      inputText: ""
    };
  },
  watch: {
    dialogTripChangeVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    if (this.dialogType === "tripChange") {
      this.title = "行程期间变更事由";
      this.placeholder = "请输入100字以内变更事由";
    } else if (this.dialogType === "tripDelay") {
      this.title = "行程延期事由";
      this.placeholder = "请输入100字以内延期事由";
    }
  },
  methods: {
    closeClick() {
      this.dialogTripChangeVisible = false;
    },
    confirmClick() {
      if (this.isMust && this.inputText.length === 0) {
        this.$message.error("输入事由不能为空!");
        return;
      }
      this.dialogTripChangeVisible = false;
      const data = {
        type: this.dialogType,
        value: this.inputText
      };
      this.$emit("getTripChangeValue", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 700px;
  height: 314px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    margin: 52px 20px 16px 20px;
    width: 660px;
    height: 200px;
    .text-area {
      &/deep/ .el-textarea__inner {
        padding: 5px 10px;
        height: 200px;
        background-color: #F5F7FA;
        border: 1px solid #E8ECF2;
      }
      &/deep/ .el-input__count {
        background-color: #F5F7FA;
      }
    }
  }
  .confirm-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 72px;
    height: 32px;
  }
}
.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
