var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-alert-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "", "show-close": false, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("div", { staticClass: "dialog-contain" }, [
            _c(
              "div",
              { staticClass: "header-contain" },
              [
                _vm._t("header", function () {
                  return [
                    _c("div", { staticClass: "title-contain" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "el-dialog__headerbtn",
                        attrs: { type: "button", "aria-label": "Close" },
                        on: { click: _vm.closeClick },
                      },
                      [
                        _c("i", {
                          staticClass: "el-dialog__close el-icon el-icon-close",
                          staticStyle: { color: "#909399" },
                        }),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "dialog-body-contain" },
              [
                _vm._t("body", function () {
                  return [
                    _c("el-input", {
                      attrs: {
                        oninput: "value=value.replace(/[^\\d.]/g,'')",
                        type: "text",
                        clearable: "",
                        placeholder: "请输入" + _vm.title,
                      },
                      model: {
                        value: _vm.input,
                        callback: function ($$v) {
                          _vm.input = $$v
                        },
                        expression: "input",
                      },
                    }),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "dialog-bottom-contain" },
              [
                _vm._t("footer", function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-contain" },
                      [
                        _c(
                          "en-button",
                          {
                            staticClass: "button",
                            attrs: { size: "mini", type: "wihte" },
                            on: { click: _vm.close },
                          },
                          [_vm._v(" 取 消 ")]
                        ),
                        _c(
                          "en-button",
                          {
                            staticClass: "button",
                            attrs: { size: "mini" },
                            on: { click: _vm.confirmClick },
                          },
                          [_vm._v(" 确 定 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }