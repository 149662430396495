<template>
  <div class="trip-detail-contain">
    <!-- 补助金额修改弹框 -->
    <tripInputDialog
      v-if="amountEditVisible"
      title="补助金额"
      :init-value="tripDetailData.subsidyAmount"
      :dialog-visible.sync="amountEditVisible"
      @getInputDialogConfirm="getInputConfirmValue"
    ></tripInputDialog>

    <!-- 新增节点类型选择弹框 -->
    <en-trip-node
      v-if="tripNodeShow"
      :dialog-visible.sync="tripNodeShow"
      @selectaddTripNode="selectAddTripNode"
    ></en-trip-node>

    <!-- 选择关联行程单弹框 -->
    <en-itinerary
      v-if="chooseItineraryShow"
      :dialog-visible.sync="chooseItineraryShow"
      :trip-data-list="transferableTravelList"
      :only-choose-itinerary="true"
      @selectTripData="selectTripData"
    ></en-itinerary>

    <!-- 删除行程单弹框 -->
    <en-tips-dialog
      :dialog-visible.sync="deleteTipsDialogVisible"
      title="温馨提醒:"
      :message="deleteTipsMessage"
      @getEnTipsDialogConfirm="confirmDeleteTipsDialog"
    />

    <!-- 转移节点时是否删除行程单 弹框 -->
    <en-confirm-dialog
      v-if="enConfirmDialogShow"
      :dialog-visible.sync="enConfirmDialogShow"
      :show-cancel="true"
      tips-text="当前行程已不存在有效节点，是否删除"
      @getComfirmValue="getEnComfirmValue"
    ></en-confirm-dialog>

    <!-- 预算占用 -->
    <tripOccupy
      v-if="tripOccupyShow"
      :dialog-visible.sync="tripOccupyShow"
    ></tripOccupy>

    <!-- 行程节点详情 -->
    <nodeDetail
      v-if="nodeDetailShow"
      :dialog-visible.sync="nodeDetailShow"
      :all-node-list="allDetailNodeList()"
      :current-node="nodeTempClickRow"
    ></nodeDetail>

    <!-- 预借差旅费弹框? -->
    <apply v-if="applyShow" :dialog-visible.sync="applyShow"></apply>

    <!-- 行程事由 -->
    <tripMemoDialog
      v-if="tripMemoDialogShow"
      :dialog-visible.sync="tripMemoDialogShow"
      :dialog-type="tripMemoDialogType"
      :is-must="tripMemoDialogMust"
      @getTripChangeValue="getTripDialogValue"
    ></tripMemoDialog>

    <!-- 新增补单节点 -->
    <supplement
      v-if="supplementShow"
      :origin-data="nodeTempData"
      :dialog-visible.sync="supplementShow"
      @addNewTripNode="addNewTripNode"
      @updateTripNode="updateTripNode"
    >
    </supplement>

    <!-- 超标事由弹框 -->
    <overStandardDialog
      v-if="overStandardVisible"
      :dialog-visible.sync="overStandardVisible"
      @getOverStandardMemo="getOverStandardMemo"
    ></overStandardDialog>

    <!-- 行程期间变更事由弹窗 -->
    <addChangeReason
      v-if="addChangeReasonVisible"
      :dialog-visible.sync="addChangeReasonVisible"
      @getChangeReason="getChangeReason"
    ></addChangeReason>

    <!-- 行程期间重叠提示 -->
    <en-tips-dialog
      :dialog-visible.sync="reasonTipsDialogVisible"
      title="温馨提醒:"
      :message="reasonTipsMessage"
      @getEnTipsDialogConfirm="confirmReasonTipsDialog"
    />
    <!----分隔线之上是一些弹框----分隔线之下是页面数据----->

    <div class="content-main-contain" :style="isShowBottomOperationButton()||flowBtnList.length ? 'height: calc(100% - 64px)':''">
      <!-- 顶部条 + 行程概要 -->
      <div class="top-contain">
        <!-- 顶部条 -->
        <div class="top-bar">
          <div class="left-contain" @click="backClick">
            <en-icon
              name="fanhui"
              size="14px;"
              :style="{ fill: '#A9B5C6' }"
              class="back-icon"
            />
            <span class="title">{{ tripDetailData.tripNo ? tripDetailData.tripNo : "行程单" }}</span>
            <en-trip-bill-status
              v-if="tripDetailData.tripStatus"
              :is-dark="true"
              :status="Number(tripDetailData.tripStatus)"
              class="status"
            ></en-trip-bill-status>
          </div>
          <!-- 顶部右侧操作按钮 -->
          <div class="right-contain">
            <div
              v-for="item in rightButtonList"
              :key="item.key"
              style="display:block;margin-left:18px;"
            >
              <en-button
                type="text"
                icon-color="red"
                :icon="item.icon"
                @click="topRightBtnClick(item)"
              >
                {{ item.name }}
              </en-button>
            </div>
          </div>
        </div>

        <!-- 行程概要 -->
        <tripSummery
          :trip-data="tripDetailData"
          @hasChangeDelyDate="hasChangeDely"
        ></tripSummery>
      </div>

      <!-- 中间黑色8px圆角分隔 -->
      <div class="seaprator-contain">
        <div class="seaprator-top"></div>
        <div class="seaprator-bottom"></div>
      </div>

      <!-- 行程节点 -->
      <div class="bottom-contain">
        <div class="bottom-bar">
          <span class="title">行程节点</span>
          <en-icon v-if="addButton" class="icon" size="18" name="icontianjia" style="cursor: pointer;" @click.native="addNodeClick"></en-icon>
          <!-- UI修改,暂时废弃
          <en-button
            v-if="addButton"
            class="button"
            icon="tianjia-anniutoubu"
            icon-color="#fff"
            @click="addNodeClick"
          >新增</en-button>
          -->
        </div>

        <div class="node-contain" :class="{noNode: noNodeMode}">
          <div v-if="!noNodeMode" class="left-line"></div>
          <div v-if="!noNodeMode" class="right-line"></div>
          <div v-if="!noNodeMode" class="bottom-line"></div>

          <div v-if="noNodeMode" class="no-node-contain">
            <img class="no-node-img" src="./data/trip_no_node.png" />
            <div class="no-node-text">
              点击添加节点
            </div>
          </div>

          <div
            v-for="nodeItem in tripDetailData.tripNodeList"
            v-else
            v-show="nodeItem.tripList.length !== 0"
            :key="nodeItem.key"
            class="node-section-contain"
          >
            <div class="section-top">
              {{ nodeItem.tripDate }}
            </div>

            <div class="node-detail-contain">
              <div
                v-for="item in nodeItem.tripList"
                :key="item.key"
                class="node-detail"
                @click="nodeCellClick(item)"
              >
                <div
                  class="node-detail-content"
                  :style="
                  Number(item.status) === 7 ? 'opacity: 0.5;' : 'opacity: 1;'
                "
                >
                  <div class="node-first-contain">
                    <div v-if="item.tripNodeType === '000' || (item.tripNodeType === '002' && item.type === '000')" class="icon-contain">
                      <en-icon
                        size="40px"
                        :style="{ fill: '#49BEF2' }"
                        :name="tripNodeTypeIconName(item)"
                      >
                      </en-icon>
                    </div>
                    <div v-else class="icon-contain icon-contain-bg" :class="{
                      'hotel': (item.tripNodeType === '001' || (item.tripNodeType === '002' && item.type === '001')),
                      'train': (item.tripNodeType === '004' || (item.tripNodeType === '002' && item.type === '002')),
                      'car': (item.tripNodeType === '003' || (item.tripNodeType === '002' && item.type === '003')),
                      'other': (item.tripNodeType === '002' && item.type === '004')
                    }">
                      <en-icon
                        size="20px"
                        :style="{ fill: '#fff' }"
                        :name="tripNodeTypeIconName(item)"
                      >
                      </en-icon>
                    </div>

                    <div class="explain">
                      <en-div-hover
                        class="text"
                        :detail="tripNodeName(item)"
                      ></en-div-hover>
                      <el-popover
                        v-if="item.standardFlag === '001'"
                        popper-class="custom-detail-popover"
                        placement="bottom-start"
                        trigger="hover"
                      >
                        <div class="tips-popover">
                      <span
                      >{{ item.standardMemo }}</span
                      >
                        </div>

                        <en-icon
                          slot="reference"
                          name="chaobiaoshiyou-wodehangcheng"
                          :style="{ fill: '#F76B6B' }"
                          size="16px"
                          class="icon"
                        ></en-icon>
                      </el-popover>
                      <en-icon
                        v-if="item.tripNodeType === '002'"
                        slot="reference"
                        name="budan-wodehangcheng"
                        :style="{ fill: '#3e90fe' }"
                        size="18px"
                        class="icon"
                      ></en-icon>
                    </div>
                  </div>
                  <div class="time">
                    {{ tripDateTime(item) }}
                  </div>
                  <div class="price">
                    {{ "¥" + item.totalAmount }}
                  </div>
                </div>
                <div>
                  <en-trip-node-status
                    :status="Number(item.status)"
                  ></en-trip-node-status>
                </div>
                <div
                  class="operation"
                  :style="operationButton(item).length > 0 ? '' : 'visibility: hidden;'"
                >
                  <div
                    v-for="obj in operationButton(item)"
                    :key="obj.id"
                    class="operation-button"
                    onClick="event.cancelBubble = true"
                    @click="operationButtonClick(item,obj)"
                  >
                    <en-icon
                      :name="obj.icon"
                      :style="{ fill: obj.color }"
                    ></en-icon>
                  </div>
                </div>

                <div
                  class="separator"
                  :style="
                  nodeItem.tripList.indexOf(item) ===
                  nodeItem.tripList.length - 1
                    ? 'visibility:hidden;'
                    : ''
                "
                ></div>
              </div>
            </div>
          </div>

          <div v-if="!noNodeMode" class="node-bottom-contain">
            <div class="top-space"></div>
            <div class="dashed">
              <div class="dashed-line"></div>
              <div class="dashed-bottom"></div>
            </div>
            <div v-if="!noNodeMode" class="amount-detail">
              <div
                v-if="subsidyMoney"
                style="width:30px;height:35px;display:inline-block;"
                @click="editSubsidy ? amountEditVisible = true : '' "
              >
                <en-icon
                  name="bianji-kuaijiecaozuo"
                  class="edit"
                  size="18px"
                  :style="{ fill: '#3e90fe' }"
                ></en-icon>
              </div>

              <el-popover
                v-if="subsidyMoney"
                popper-class="custom-detail-popover"
                placement="bottom-start"
                trigger="hover"
              >
                <div class="amount-popover">
                  <span class="content">{{ tripBottomDetail() }}</span>
                  <span class="content">总计金额：{{ tripDetailData.tripTotalAmount }}</span>
                  <span class="content">企业支付：{{ tripDetailData.hasBalance}}</span>
                  <span class="content">补助金额：{{ tripDetailData.subsidyAmount }}</span>
                  <div
                    style="background-color:#E8ECF2;height:1px;margin:1px 0;"
                  ></div>
                  <span class="last-content">报销金额：</span>
                  <span class="last-amount">¥{{ tripDetailData.expenseAmount }}</span>
                </div>
                <div
                  slot="reference"
                  style="height:12px;margin-top:2px;;display:inline-block;"
                >
                  <span style="color:#333;">补助金额：</span>
                  <span style="color:#F78528;">{{ tripDetailData.subsidyAmount }}</span>
                  <span style="color:#333;margin-left:10px;">报销金额：</span>
                  <span style="color:#636C78;">{{ tripDetailData.expenseAmount }}</span>
                </div>
              </el-popover>
            </div>
            <div class="node-total-contain">
              <span style="color:#636C78;">总价：</span>
              <span style="color:#F78528;font-size:14px">¥ </span>
              <span style="color:#F78528;font-size:16px;font-weight:500;"
              >{{ tripDetailData.tripTotalAmount > 0 ? tripDetailData.tripTotalAmount : "0.00"}}</span
              >
            </div>
          </div>
        </div>

        <div v-if="isHasShareUserList" class="share-contain">
          <en-icon
            size="12px"
            class="gongxiang-icon"
            :style="{ fill: '#ffad2c' }"
            name="tishi"
          ></en-icon>
          <div class="text">{{ shareUserDetail }}</div>
        </div>

        <div
          v-if="false"
          class="operation-contain"
          :style="(deleteButton()||saveButton||submitButton||baoXiaoButton||jieShuButton||endButton) ? 'display:flex;' : 'display:none;'"
        >
          <en-button v-if="deleteButton()" class="operation-button" type="red" @click="handleDeleteButton">
            删除
          </en-button>
          <en-button v-if="saveButton" class="operation-button" type="green" @click="handleSaveButton">
            保存
          </en-button>
          <en-button v-if="submitButton" class="operation-button" type="blue" @click="handleSubmitButton">
            提交
          </en-button>
          <en-button v-if="baoXiaoButton" class="operation-button" type="blue" @click="handleBaoXiaoButton">
            报销
          </en-button>
          <en-button v-if="jieShuButton" class="operation-button" type="red" @click="handleJieShuButton">
            结束
          </en-button>
          <en-button v-if="endButton" class="operation-button" type="red" @click="handleEndButton">
            终止
          </en-button>
        </div>
      </div>

      <!-- 中间黑色8px圆角分隔 -->
      <div v-if="isShowFlow" class="seaprator-contain">
        <div class="seaprator-top"></div>
        <div class="seaprator-bottom"></div>
      </div>
      <div v-if="isShowFlow" class="flow-contain">
        <div class="flow-title">
          查看流程
          <div class="flow-title-right" @click="isExpose = !isExpose">
            <div class="status" v-if="mdDetailData.mdInfo" >{{ mdDetailData.mdInfo.nodeName }}</div>
            <en-icon
              class="icon"
              :class="{ expose: isExpose }"
              name="zhankai"
              size="14px"
            ></en-icon>
          </div>
        </div>
        <div v-if="isExpose" class="flow-list-contain">
          <cardList :data="mdDetailData" :tripDataId="tripDetailData.businessKey"/>
          <!-- <div v-for="item in flowList" :key="item.key" class="flow-item-contain">
            <div class="item-left-contain">
              <div class="left-line"></div>
              <en-icon class="left-icon" :name="flowListIcon(item)" size="16px"></en-icon>
            </div>
            <div class="item-right-contain">
              <en-user-logo
                class="logo"
                :imageUrl="item.userLogo"
                :user-name="item.name"
                size="40"
              ></en-user-logo>
              <div class="name">
                {{ item.name }}
              </div>
              <div class="time">
                {{ item.createTime }}
              </div>
              <div class="approval">
                {{ item.nodeName }}
              </div>
              <div class="reason">
                {{ item.approvalComment }}
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- 中间黑色8px圆角分隔 -->
      <div v-if="isShowFlow" class="seaprator-contain">
        <div class="seaprator-top"></div>
        <div class="seaprator-bottom"></div>
      </div>
    </div>
    <div class="operation-contain" v-if="isShowBottomOperationButton()||flowBtnList.length">
      <div
        v-if="isShowBottomOperationButton()"
        class=""
        :class="{ expose: isExpose }"
        style="dispaly:inline"
      >
        <en-button v-if="deleteButton()" class="operation-button" type="red" @click="handleDeleteButton">
          删除
        </en-button>
        <en-button v-if="saveButton" class="operation-button" type="green" @click="handleSaveButton">
          保存
        </en-button>
        <en-button v-if="submitButton" class="operation-button" type="blue" @click="handleSubmitButton">
          提交
        </en-button>
        <en-button v-if="baoXiaoButton" class="operation-button" type="blue" @click="handleBaoXiaoButton">
          报销
        </en-button>
        <en-button v-if="jieShuButton" class="operation-button" type="red" @click="handleJieShuButton">
          结束
        </en-button>
        <en-button v-if="endButton" class="operation-button" type="red" @click="handleEndButton">
          终止
        </en-button>
      </div>
      <div
        class=""
        style="dispaly:inline"
        v-if="flowBtnList.length"
      >
        <span v-for="(btn, key) in flowBtnList" :key="key" class="">
        <en-button v-if="btn.btnKey==='agree'" type="blue" @click="btnHandle(btn)" >
          {{ btn.btnName }}
        </en-button>
        <en-button v-if="btn.btnKey==='propose'||btn.btnKey==='proposeTo'||btn.btnKey==='signed'" type="green" @click="btnHandle(btn)" >
          {{ btn.btnName }}
        </en-button>
        <en-button v-if="btn.btnKey==='reject'||btn.btnKey==='rebut'||btn.btnKey==='stop'||btn.btnKey==='getback'" type="red" @click="btnHandle(btn)" >
          {{ btn.btnName }}
        </en-button>
        </span>
      </div>
    </div>
    <!--业务建模弹框-->
    <businessDialog
      v-if="businessDialog"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialog"
      @submitSuccess="submitSuccess">
    </businessDialog>

    <!--共享人选择-->
    <en-dialog title="选择共享人" :visible="personDilogShow" width="900px" append-to-body :close-on-click-modal="false"
               @close="personDilogShow=false">
      <en-transfer-data v-model="sharePersonList" :config="shareTransferConfig"></en-transfer-data>
      <span slot="footer" class="dialog-footer">
        <en-button type="primary" @click="submitSelect">确 定</en-button>
      </span>
    </en-dialog>
    <businessFunction sourceType="toDo" :id="btnObj.id" ref="businessFunction" :templateId="btnObj.templateId" :p-id="btnObj.pId"></businessFunction>
  </div>
</template>

<script>
// import dayjs from "dayjs";
// import { request } from "en-js";
import enDivHover from "@/components/en-div-hover";
import businessDialog from "@/components/businessDetail/businessDialog";
import cardList from "@/components/businessDetail/components/columnRendering/seeFlow/components/cardList";
import enTripNodeStatus from "../components/en-trip-node-status";
import enTripNode from "../components/en-trip-node";
import enItinerary from "../components/en-itinerary";
import tripSummery from "./components/trip-summary";
import tripInputDialog from "./components/trip-input-dialog";
import tripOccupy from "./components/trip-occupy-dialog";
import nodeDetail from "./nodeDetail";
import apply from "./nodeDetail/views/apply";
import tripMemoDialog from "./components/tripChangeDialog";
import supplement from "../supplement";
import enTipsDialog from "../../../components/en-tips-dialog";
import tripDetailData from "./tripDetailData";
import overStandardDialog from "../hotel/components/over-standard-dialog";
import addChangeReason from "./components/add-change-reason";
import enConfirmDialog from "../components/en-confirm-dialog";
import enTripBillStatus from "../components/en-trip-bill-status";
import enTransferData from "../../../components/en-transfer-data";

// import { cloneDeep } from "lodash";
// import businessFunction from "@/components/businessDetail/mixins/buttonFunction/businessFunction.vue";

export default {
  name: "TripDetail",
  components: {
    tripSummery,
    tripOccupy,
    tripInputDialog,
    nodeDetail,
    apply,
    tripMemoDialog,
    supplement,
    enTripNodeStatus,
    enTripNode,
    enItinerary,
    enDivHover,
    enTipsDialog,
    overStandardDialog,
    addChangeReason,
    enConfirmDialog,
    enTripBillStatus,
    businessDialog,
    enTransferData,
    cardList,
    // businessFunction
    businessFunction: () => import("@/components/businessDetail/mixins/buttonFunction/businessFunction.vue")
  },
  data() {
    return {
    };
  },
  mixins: [tripDetailData]
};
</script>

<style lang="scss" scoped>
.trip-detail-contain {
  background-color: #FFFFFF;
  // background-color:#1d2532;
  width: 100%;
  height: 100%;
  font-size: 12px;
  .content-main-contain {
    height: 100%;
    overflow: auto;

    .top-contain {
      background: #fff !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;

      .top-bar {
        height: 49px;
        border-bottom: 1px solid #e8ecf2;
        display: flex;
        justify-content: space-between;
        .left-contain {
          display: flex;
          .back-icon {
            display: block;
            margin: 18px 2px 0 16px;
          }
          .title {
            display: block;
            padding-top: 2px;
            height: 48px;
            line-height: 48px;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            color: #333;
          }
          .status {
            margin-top: 14px;
            margin-left: 10px;
          }
        }
        .right-contain {
          display: flex;
          align-items: center;
          margin-right: 20px;
        }
      }
    }
    .seaprator-contain {
      background: #1d2532;
      width: 100%;
      .seaprator-top {
        background: rgba(255, 255, 255, 1);
        border-radius: 0 0 4px 4px;
        height: 4px;
        width: 100%;
      }
      .seaprator-bottom {
        margin-top: 8px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px 4px 0 0;
        height: 4px;
        width: 100%;
      }
    }
    .bottom-contain {
      position: relative;
      background: #fff !important;
      min-height: 225px;
      width: 100%;
      // margin-bottom: 16px;
      padding-bottom: 16px;
      //border-radius: 5px;
      .bottom-bar {
        height: 48px;
        border-bottom: 1px solid #e8ecf2;
        display: flex;
        align-items: center;
        .title {
          display: block;
          margin-left: 20px;
          height: 48px;
          line-height: 48px;
          text-align: left;
          font-size: 12px;
          font-weight: bold;
          color: #333;
        }
        .icon {
          margin-left: 10px;
          fill: #75AADC;
        }
        .button {
          margin: 8px 20px 0 0;
          width: 72px;
          height: 32px;
        }
      }
      .node-contain {
        padding-top: 4px;
        margin: 0 20px 0 20px;
        position: relative;
        &.noNode {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 245px;
        }
        .left-line {
          position: absolute;
          top: 65px;
          left: 0;
          bottom: 0;
          width: 10px;
          border-left: 1px solid #e8ecf2;
          border-radius: 0 0 0 4px;
        }
        .right-line {
          position: absolute;
          top: 65px;
          right: 0;
          bottom: 0;
          width: 10px;
          border-right: 1px solid #e8ecf2;
          border-radius: 0 0 4px 0;
        }
        .bottom-line {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 10px;
          border-bottom: 1px solid #e8ecf2;
          border-radius: 0 0 4px 4px;
        }
        .no-node-contain {
          width: 100%;
          margin-top: 16px;
          border-radius: 4px;
          // border: 1px solid #e8ecf2;
          text-align: center;
          .no-node-img {
            width: 89px;
            height: 93px;
          }
          .no-node-text {
            padding-top: 13px;
            font-size: 14px;
            color: #91a1b7;
          }
        }
        .node-section-contain {
          margin-top: 16px;
          .section-top {
            height: 40px;
            width: 120px;
            text-align: left;
            padding-left: 25px;
            background-color: #f6fafd;
            border: 1px solid #dce5ec;
            border-bottom-width: 0;
            border-radius: 4px 4px 0 0;
            line-height: 40px;
            color: #333;
          }
          .node-detail-contain {
            border: 1px solid #e8ecf2;
            border-radius: 0 4px 4px 4px;
            .node-detail {
              position: relative;
              height: 60px;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              text-align: left;
              line-height: 60px;
              .node-detail-content {
                position: relative;
                height: 60px;
                width: calc(100% - 60px - 142px);
                display: flex;
                justify-content: space-between;
                text-align: left;
                line-height: 60px;
                .node-first-contain {
                  display: flex;
                  justify-content: flex-start;
                }
              }

              .icon-contain {
                flex-shrink: 0;
                margin: 11px 19px 0 0;
              }
              .icon-contain-bg {
                width: 40px;
                height: 40px;
                background: #F7BF27;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                &.car {
                  background: #26C393;
                }
                &.hotel {
                  background: #F7BF27;
                }
                &.train {
                  background: #F7BF27;
                }
                &.other {
                  background: #26C393;
                }
              }
              .icon {
                flex-shrink: 0;
                margin: 11px 19px 0 0;
              }
              .explain {
                padding-right: 10px;
                min-width: 150px;
                width: calc(100% - 78px - 240px - 200px);
                color: #636c78;
                //display: inline-block;
                display: flex;
                .text {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .icon {
                  display: block;
                  margin: 18px 0 0 5px;
                }
              }
              .time {
                padding-right: 10px;
                width: 240px;
                color: #636c78;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .price {
                padding-right: 10px;
                width: 200px;
                color: #f78528;
              }

              .operation {
                flex-shrink: 0;
                width: 130px;
                visibility: hidden;
                padding-right: 10px;
                display: flex;
                justify-content: flex-end;
                .operation-button {
                  width: 38px;
                  text-align: right;
                  & /deep/ .en-icon {
                    margin-top: 19px;
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              &:hover {
                background-color: #f5f8fc;
                .operation {
                  visibility: visible;
                }
              }
              .separator {
                position: absolute;
                left: 20px;
                bottom: 0;
                background-color: #dce5ec;
                height: 1px;
                width: calc(100% - 43px);
              }
            }
          }
        }
        .node-bottom-contain {
          height: 75px;
          width: 100%;
          background-color: #F5F7FB;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          .top-space {
            height: 23px;
            background-color: #FFFFFF;
          }
          .dashed {
            position: relative;
            height: 10px;
            overflow: hidden;
            background-color: #FFFFFF;

            .dashed-line {
              margin-top: 5px;
              border-top: 1px dashed #e8ecf2;
              height: 1px;
            }
            .dashed-bottom {
              height: 4px;
              background-color: #F5F7FB;
            }
          }
          .dashed:before, .dashed:after{
            display: block;
            position: absolute;
            content: "";
            width:8px;
            height:8px;
            background-color: #fff;
            border-radius:50%;
            border: 1px solid #e8ecf2;
            //top: -5px;
          }
          .dashed:before{
            left: -5px;
          }
          .dashed:after{
            top: 0;
            right: -5px;
          }

          .node-total-contain {
            display: block;
            float: right;
            margin-top: 11px;
            margin-right: 19px;
          }
          .amount-detail {
            float: left;
            padding-top: 11px;
            padding-left: 18px;
            height: 50px;
            text-align: left;
            display: flex;
          }
        }
      }
    }
    .flow-contain {
      background-color: #fff;
      border-radius: 4px;
      .flow-title {
        padding: 0 20px;
        height: 49px;
        line-height: 49px;
        text-align: left;
        color: #333;
        font-weight: bold;
        .flow-title-right {
          float: right;
          display: flex;
          align-items: center;
          height: 22px;
          line-height: 22px;
          margin-top: 12px;
          .status {
            color: #606060;
            line-height: 22px;
            margin-right: 5px;
          }
          .icon {
            fill: rgb(169,181,198);
            transform: scaleX(0.9);
            transition: transform 0.3s;
            cursor: pointer;
            &.expose {
              transform: rotateZ(180deg);
            }
          }
        }
      }
      .flow-list-contain {
        border-top: 1px solid #e8ecf2;
        padding-top: 16px;
        margin: 0 16px 16px 16px;
        overflow-y: auto;
        padding-bottom:20px;
        .flow-item-contain {
          height: 90px;
          .item-left-contain {
            position: relative;
            float: left;
            height: 90px;
            width: 32px;
            .left-line {
              position: absolute;
              top: 0;
              left: 8px;
              width: 2px;
              height: 90px;
              background-color: #e8ecf2;
            }
            .left-icon {
              position: absolute;
              top: 32px;
              left: 0;
              fill: #73ade8;
            }
          }
          .item-right-contain {
            position: relative;
            height: 80px;
            margin-left: 32px;
            background-color: #f6fafd;
            border-radius: 4px;
            border: 1px solid #e8ecf2;
            .logo {
              position: absolute;
              top: 20px;
              left: 21px;
            }
            .name {
              position: absolute;
              top: 20px;
              left: 78px;
              font-size: 14px;
              color: #000;
            }
            .time {
              position: absolute;
              top: 23px;
              right: 19px;
              color: #91a1b7;
            }
            .approval {
              position: absolute;
              bottom: 20px;
              left: 78px;
              color: #606060;
            }
            .reason {
              position: absolute;
              right: 20px;
              bottom: 20px;
              color: #636c78;
            }
          }
        }
      }
    }
    .share-contain {
      margin-top: 16px;
      margin-left: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      .text {
        color: #606060;
        padding-left: 5px;
      }
    }
  }

  .operation-contain {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8ecf2;
    padding: 0 20px;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background:#fff;
    .operation-button {
      margin-left: 6px;
      width: 72px;
      height: 32px;
    }
    div>span{
      margin-left: 6px;
    }
  }
}
</style>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  // margin-top: 0;
  // background-color: red;
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  // margin-bottom: 0;
  // background-color: red;
  // border: 1px solid #ebeef5;
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-detail-popover {
  .popper__arrow {
    &::after {
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .amount-popover {
    margin: -13px;
    padding: 12px;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: 114px;
    min-width: 267px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    .content {
      display: block;
      padding-bottom: 2px;
      color: #fff;
    }
    .last-content {
      color: #fff;
    }
    .last-amount {
      color: #f78528;
    }
  }
  .tips-popover {
    margin: -13px;
    padding: 12px;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    min-height: 25px;
    max-width: 370px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
  }
}
</style>
