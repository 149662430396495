var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        title: "行程期间变更事由",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        { staticClass: "cent" },
        [
          _c("el-input", {
            ref: "comment",
            staticClass: "comment",
            attrs: {
              type: "textarea",
              maxlength: "100",
              placeholder: "请输入行程期间变更事由（100字以内）",
              rows: 10,
            },
            on: {
              focus: function ($event) {
                _vm.focusInput = true
              },
              blur: function ($event) {
                _vm.focusInput = false
              },
            },
            model: {
              value: _vm.inputText,
              callback: function ($$v) {
                _vm.inputText = $$v
              },
              expression: "inputText",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }