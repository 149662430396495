import { request } from "en-js";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { tripService } from "@/api";
import { enEqual } from "@/tools/compare";
import Store from "@/store";
import { newBusinessDialogParams, viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
// newBusinessDialogParams

// 校验的方法
const checkMethods = {
  // 是否是(未提交)且 过时的节点
  isOverTimeUnsubmitNode(item) {
    if (item.tripNodeType === "000") {
      if (item.status === "000") {
        if (item?.takeoffTime) {
          if (dayjs(item.takeoffTime).isBefore(dayjs(new Date()))) {
            return true;
          }
        }
      }
      return false;
    }
    if (item.tripNodeType === "001") {
      if (item.status === "000") {
        const curDate = new Date();
        const arrivalDate = item.latestArrivalTime ? dayjs(item.latestArrivalTime) : dayjs(item.arrivalDate);
        if (dayjs(curDate).hour() < 12) {
          if (dayjs(arrivalDate).diff(curDate) < -1) {
            return true;
          }
        } else if (dayjs(arrivalDate).diff(curDate) < 0) {
          return true;
        }
      }
      return false;
    }
    if (item.tripNodeType === "003") {
      if (item.status === "000") {
        if (item.type === "0") {
          if (dayjs(item.createTime).diff(new Date()) < 0) {
            return true;
          }
        } else if (item.type === "1") {
          if (dayjs(item.departureTime).diff(new Date()) < 0) {
            return true;
          }
        }
      }
    }
    return false;
  },
  // 是否存在延期
  isExistDelay() {
    if (this.tripDetailData.approvalPassFlag === "001") {
      if (dayjs(this.tripDetailData.tempStartDate).isBefore(dayjs(this.tripDetailData.delayStartDate))
        || dayjs(this.tripDetailData.tempEndDate).isAfter(dayjs(this.tripDetailData.delayEndDate))) {
        return true;
      }
    }
    return false;
  }
};

// 数据处理方法s
const dataAndUIMethods = {
  // 清空数据
  clearAllData() {
    this.tripDetailData = {};
    this.rightButtonList = [];
    this.mdDetailData = {};
    this.isShowFlow = false;
    this.flowList = [];
    this.flowBtnList = [];
    this.hasChangeDate = false;
  },
  // 右上角按钮权限
  leftButtonList() {
    this.rightButtonList = [];
    if (this.tripDetailData?.businessKey?.length > 0) {
      this.rightButtonList.push({
        name: "申请单",
        icon: "shenqingdan-wodehangcheng",
        id: "shenqingdan"
      });
    }
    if (this.tripDetailData?.expenseId?.length > 0) {
      this.rightButtonList.push({
        name: "报销单",
        icon: "baoxiaodan-wodehangcheng",
        id: "baoxiaodan"
      });
    }
    if (this.tripDetailData?.id?.length > 0) {
      this.rightButtonList.push({
        name: "共享",
        icon: "gongxiang-wodehangcheng",
        id: "gongxiang"
      });
    }
  },
  // 节点操作按钮权限
  operationButton(item, isDetail) {
    const editBtn = { // 编辑
      id: "bianji",
      icon: "bianji-liebiao",
      color: "#5d5d5d"
    };
    const changeBtn = { // 转移
      id: "zhuanyi",
      icon: "qiehuan",
      color: "#3e90fe"
    };
    const deleteBtn = { // 删除
      id: "shanchu",
      icon: "shanchu-liebiao",
      color: "#F76B6B"
    };
    const reBook = { // 重新预定
      id: "chongxinyuding",
      icon: "zaiciyuding-liebiao",
      color: "#5d5d5d"
    };
    const tuiPiao = { // 退票
      id: "tuipiao",
      icon: "tuipiao-liebiao",
      color: "#5d5d5d"
    };
    const gaiQian = { // 改签
      id: "gaiqian",
      icon: "xiugai1",
      color: "#5d5d5d"
    };
    const cancel = { // 取消
      id: "quxiao",
      icon: "quxiao-liebiao",
      color: "#F76B6B"
    };
    if (this.tripDetailData?.tripStatus === "000") { // 未提交
      if (item.tripNodeType === "002") { // 补单
        return [editBtn, deleteBtn];
      }
      return [deleteBtn];
    }
    if (this.tripDetailData?.tripStatus === "002") { // 待处理
      if (item.approvalPassFlag === "000") { // 审批未通过
        if (item.tripNodeType === "002") {
          return [editBtn, deleteBtn];
        }

        return [deleteBtn];
      }
      if (item.approvalPassFlag === "001") { // 审批已通过
        if (item.tripNodeType === "000") { // 机票
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
          if (item.status === "011") { // 已出票
            if (isDetail) {
              return [tuiPiao, gaiQian];
            }
            return [];
          }
        } else if (item.tripNodeType === "001") { // 酒店
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
        } else if (item.tripNodeType === "003") { // 用车
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
        } else if (item.tripNodeType === "002") { // 补单
          if (item.status === "013") { // 已审批
            return [cancel];
          }
          if (item.status === "006") { // 已取消
            return [deleteBtn];
          }
        }
      }
    }
    if (this.tripDetailData?.tripStatus === "003"
      || this.tripDetailData?.tripStatus === "004"
      || this.tripDetailData?.tripStatus === "005") { // 未执行,执行中,已执行
      if (item.approvalPassFlag === "000") { // 审批未通过
        if (item.tripNodeType === "002") {
          return [editBtn, deleteBtn];
        }
        return [deleteBtn];
      }
      if (item.approvalPassFlag === "001") { // 审批已通过
        if (item.tripNodeType === "000") { // 机票
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
          if (item.status === "011") { // 已出票
            if (isDetail) {
              return [tuiPiao, gaiQian];
            }
            return [changeBtn];// [changeBtn, tuiPiao, gaiQian];
          }
          if (item.status === "004"
            || item.status === "008"
            || item.status === "010"
            || item.status === "014"
            || item.status === "017") { // 已使用/已退票/已改签/未使用/已退款
            return [changeBtn];
          }
        } else if (item.tripNodeType === "001") { // 酒店
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
          if (item.status === "003" || item.status === "004") { // 已预定.已使用
            return [changeBtn];
          }
        } else if (item.tripNodeType === "003") { // 用车
          if (item.status === "001") { // 订失败
            if (isDetail) {
              return [reBook, deleteBtn];
            }
            return [deleteBtn];
          }
          if (item.status === "004") { // 已使用
            return [changeBtn];
          }
          if (item.status === "006") { // 已取消
            return [deleteBtn];
          }
          if (item.status === "002" || item.status === "003") { // 预定中,已预订
            return [changeBtn, cancel];
          }
        } else if (item.tripNodeType === "002") { // 补单
          if (item.status === "013") { // 已审批
            return [changeBtn, cancel];
          }
          if (item.status === "006") { // 已取消
            return [deleteBtn];
          }
        }
      }
    }
    if ((this.tripDetailData?.tripStatus === "006"
      || this.tripDetailData?.tripStatus === "008"
      || this.tripDetailData?.tripStatus === "009")
      && item.approvalPassFlag === "001") { // 待报销.报销中.已报销 && 审批已通过
      if (item.tripNodeType === "000") { // 机票
        if (item.status === "001") { // 订失败
          return [deleteBtn];
        }
      } else if (item.tripNodeType === "001") { // 酒店
        if (item.status === "001") { // 订失败
          return [deleteBtn];
        }
      } else if (item.tripNodeType === "003") { // 用车
        if (item.status === "001" || item.status === "006") { // 订失败.已取消
          return [deleteBtn];
        }
      } else if (item.tripNodeType === "002") { // 补单
        if (item.status === "006") { // 已取消
          return [deleteBtn];
        }
      }
    }
    if (this.tripDetailData?.tripStatus === "007") { // 已终止
      if (this.tripDetailData.approvalPassFlag === "000") { // 行程单未审批通过
        if (item.approvalPassFlag === "000" && item.status === "000") { // 审批未通过.未提交
          return [deleteBtn];
        }
      } else if (this.tripDetailData.approvalPassFlag === "001") { // 行程单已审批通过
        if (item.tripNodeType === "000") { // 机票
          if (item.status === "001") { // 订失败
            return [deleteBtn];
          }
        } else if (item.tripNodeType === "001") { // 酒店
          if (item.status === "001") { // 订失败
            return [deleteBtn];
          }
        } else if (item.tripNodeType === "003") { // 用车
          if (item.status === "001" || item.status === "006") { // 订失败.已取消
            return [deleteBtn];
          }
        } else if (item.tripNodeType === "002") { // 补单
          if (item.status === "006") { // 已取消
            return [deleteBtn];
          }
        }
      }
    }
    return [];
  },
  // 节点数据
  tripNodeDataList(flag, memo) {
    const list = [];
    // console.log(this.supplementTempData);
    if (this.supplementTempData.type === "000") { // 补单机票节点
      const nodeObj = {
        standardFlag: flag,
        standardMemo: memo,
        tripNodeType: "002",
        classType: this.supplementTempData.classType,
        arrivalCity: this.supplementTempData.arrivalCity?.cityName,
        startCity: this.supplementTempData.startCity?.cityName,
        userId: this.supplementTempData.userId,
        code: this.supplementTempData.code,
        explain: this.supplementTempData.explain,
        totalAmount: this.supplementTempData.totalAmount,
        type: this.supplementTempData.type,
        arrivalDate: dayjs(this.supplementTempData.arrivalDate).format("YYYY-MM-DD")
      };
      if (this.supplementTempData.isEdit) {
        nodeObj.id = this.supplementTempData.id;
        nodeObj.tripId = this.supplementTempData.tripId;
      }
      list.push(nodeObj);
    } else if (this.supplementTempData.type === "001") { // 补单酒店节点
      const totalDays = dayjs(this.supplementTempData.departureDate).diff(dayjs(this.supplementTempData.arrivalDate), "day");
      const nodeObj = {
        standardFlag: flag,
        standardMemo: memo,
        tripNodeType: "002",
        name: this.supplementTempData.name,
        arrivalDate: dayjs(this.supplementTempData.arrivalDate).format("YYYY-MM-DD"),
        departureDate: dayjs(this.supplementTempData.departureDate).format("YYYY-MM-DD"),
        arrivalCity: this.supplementTempData.arrivalCity?.cityName,
        userId: this.supplementTempData.userId,
        explain: this.supplementTempData.explain,
        totalAmount: this.supplementTempData.totalAmount,
        type: this.supplementTempData.type,
        totalDays
      };
      if (this.supplementTempData.isEdit) {
        nodeObj.id = this.supplementTempData.id;
        nodeObj.tripId = this.supplementTempData.tripId;
      }
      list.push(nodeObj);
    } else if (this.supplementTempData.type === "002") { // 补单火车节点
      const nodeObj = {
        standardFlag: flag,
        standardMemo: memo,
        tripNodeType: "002",
        classType: this.supplementTempData.classType,
        arrivalCity: this.supplementTempData.arrivalCity,
        startCity: this.supplementTempData.startCity,
        userId: this.supplementTempData.userId,
        code: this.supplementTempData.code,
        explain: this.supplementTempData.explain,
        totalAmount: this.supplementTempData.totalAmount,
        type: this.supplementTempData.type,
        arrivalDate: dayjs(this.supplementTempData.arrivalDate).format("YYYY-MM-DD")
      };
      if (this.supplementTempData.isEdit) {
        nodeObj.id = this.supplementTempData.id;
        nodeObj.tripId = this.supplementTempData.tripId;
      }
      list.push(nodeObj);
    } else if (this.supplementTempData.type === "003") { // 补单用车节点
      const nodeObj = {
        standardFlag: flag,
        standardMemo: memo,
        tripNodeType: "002",
        arrivalDate: dayjs(this.supplementTempData.arrivalDate).format("YYYY-MM-DD"),
        startCity: this.supplementTempData.startCity,
        arrivalCity: this.supplementTempData.arrivalCity,
        userId: this.supplementTempData.userId,
        explain: this.supplementTempData.explain,
        totalAmount: this.supplementTempData.totalAmount,
        type: this.supplementTempData.type
      };
      if (this.supplementTempData.isEdit) {
        nodeObj.id = this.supplementTempData.id;
        nodeObj.tripId = this.supplementTempData.tripId;
      }
      list.push(nodeObj);
    } else if (this.supplementTempData.type === "004") { // 补单其他节点
      const nodeObj = {
        standardFlag: flag,
        standardMemo: memo,
        tripNodeType: "002",
        type: this.supplementTempData.type,
        userId: Store.getters.userInfo.userId,
        explain: this.supplementTempData.explain,
        totalAmount: this.supplementTempData.totalAmount,
        arrivalDate: dayjs(this.supplementTempData.arrivalDate).format("YYYY-MM-DD")
      };
      if (this.supplementTempData.isEdit) {
        nodeObj.id = this.supplementTempData.id;
        nodeObj.tripId = this.supplementTempData.tripId;
      }
      list.push(nodeObj);
    }
    return list;
  },
  // 删除按钮
  deleteButton(item) {
    if (this.isCreateTrip) {
      return false;
    }
    if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
      return false;
    }
    if (this.tripDetailData?.tripStatus === "000") {
      return true;
    }
    if (this.tripDetailData?.tripStatus === "005") {
      let isCanDelete = true;
      for (let j = 0; j < this.allNodeList.length; j++) {
        const node = this.allNodeList[j];
        if (node?.id !== item?.id) {
          if (!(node.status === "002" || node.status === "007")) {
            isCanDelete = false;
            break;
          }
        }
      }
      return isCanDelete;
    }
    if (this.tripDetailData?.tripStatus === "007") {
      return true;
    }
    return false;
  },
  // 节点图标名称
  tripNodeTypeIconName(val) {
    if (val.tripNodeType === "000" || (val.tripNodeType === "002" && val.type === "000")) {
      return "duorenjipiao-yuding";
    }
    if (val.tripNodeType === "001" || (val.tripNodeType === "002" && val.type === "001")) {
      return "jiudianyuding-xianxing";
    }
    if (val.tripNodeType === "004" || (val.tripNodeType === "002" && val.type === "002")) {
      return "huoche-budan";
    }
    if (val.tripNodeType === "003" || (val.tripNodeType === "002" && val.type === "003")) {
      return "yongcheyuding-xianxing";
    }
    if (val.tripNodeType === "002" && val.type === "004") {
      return "budan-jiedian";
    }
    return "";
  },
  // 节点描述
  tripNodeName(val) {
    if (val.tripNodeType === "000") {
      return `${val.depCityCh + val.depAirportCh }${val.fromTerminal || ""}→${val.arrCityCh}${val.arrAirportCh}${val.arrTerminal || ""}`;
    }
    if (val.tripNodeType === "001") {
      return val.hotelName;
    }
    if (val.tripNodeType === "002" && (val.type === "000" || val.type === "002" || val.type === "003")) {
      return `${val.startCity}→${val.arrivalCity}`;
    }
    if (val.tripNodeType === "002" && val.type === "001") {
      return val.name;
    }
    if (val.tripNodeType === "002" && val.type === "004") {
      return val.explain;
    }
    if (val.tripNodeType === "003") {
      return `${val.startName}→${val.endName}`;
    }
    return val.explain;
  },
  // 节点时间日期
  tripDateTime(val) {
    if (val.tripNodeType === "000") {
      return `${dayjs(val.takeoffTime).format("HH:mm")}→${dayjs(val.arriveTime).format("HH:mm")}`;
    }
    if (val.tripNodeType === "001") {
      return `入住${val.totalDays}天`;
    }
    if (val.tripNodeType === "003") {
      return dayjs(val.departureTime).format("HH:mm");
    }
    return " ";
  },
  // 底部报销弹出行程概况
  tripBottomDetail() {
    const day = dayjs(this.tripDetailData.tripEndDate).diff(dayjs(this.tripDetailData.tripStartDate), "day") + 1;
    return `本次行程从${this.tripDetailData.tripStartDate} / ${this.tripDetailData.tripEndDate}，共${day}天`;
  },
  // 审批流程图标
  flowListIcon(item) {
    if (item.imgFlag === "000") { // 提交
      return "chufa-liebiao";
    }
    if (item.imgFlag === "001") { // 待处理
      return "zaiciyuding-liebiao";
    }
    if (item.imgFlag === "002") { // 审批中
      return "bianji-liebiao";
    }
    if (item.imgFlag === "003") { // 已审批
      return "wancheng";
    }
    if (item.imgFlag === "004") { // 退回
      return "chehui-liebiao";
    }
    if (item.imgFlag === "005") { // 收回
      return "chehui-liebiao";
    }
    if (item.imgFlag === "006") { // 已终止
      return "shanchu-liebiao";
    }

    return "daochu-liebiao";
  },
  // 是否有底部操作按钮
  isShowBottomOperationButton() {
    if (this.deleteButton() || this.saveButton || this.submitButton || this.baoXiaoButton || this.jieShuButton || this.endButton) {
      return true;
    }
    return false;
  },
    // 所有节点详情数据
  allDetailNodeList() {
    const allList = [];
    if (this.tripDetailData.tripNodeList) {
      for (let i = 0; i < this.tripDetailData.tripNodeList.length; i++) {
        const item = this.tripDetailData.tripNodeList[i];
        if (item.tripList) {
          for (let j = 0; j < item.tripList.length; j++) {
            const node = item.tripList[j];
            if (node.tripNodeType !== "002") {
              const operationList = this.operationButton(node, true);
              const tmpList = [];
              for (let k = 0; k < operationList.length; k++) {
                const obj = operationList[k];
                if (obj.id === "tuipiao") {
                  tmpList.push(obj);
                }
                if (obj.id === "gaiqian") {
                  tmpList.push(obj);
                }
                if (obj.id === "chongxinyuding") {
                  tmpList.push(obj);
                }
              }
              node.operationList = [...tmpList];
            }
            allList.push(node);
          }
        }
      }
    }
    return allList;
  }
};

// 事件方法
const actionMethods = {
  // 返回上一页
  backClick() {
    this.$router.push("/business-travel/trip");
  },
  // 右上角按钮事件
  topRightBtnClick(item) {
    if (item.id === "shenqingdan") {
      this.applyButtonClick();
    } else if (item.id === "baoxiaodan") {
      this.reimbursementButtonClick();
    } else if (item.id === "gongxiang") {
      this.shareButtonClick();
    }
  },
  // 申请单-跳转业务建模申请单
  applyButtonClick() {
    this.businessDialogCick(this.tripDetailData.businessKey, 2, false, this.tripDetailData.businessTempId);
  },
  // 报销单-跳转业务建模报销单
  reimbursementButtonClick() {
    this.businessDialogCick(this.tripDetailData.expenseId, 1, false, this.tripDetailData.businessTempId);
  },
  // 跳转业务建模
  businessDialogCick(ID, tripType, isNew, templateId1) {
    const params = {
      id: ID,
      templateId: templateId1,
      businessType: 2,
      tripId: this.tripDetailData.id,
      tripType// tripType 1-行程报销查业务建模2行程申请直接报销查业务建模
    };
    if (isNew) {
      this.businessParams = viewBusinessDialogParams(params);
    } else {
      this.businessParams = newBusinessDialogParams(params);
    }
    this.businessDialog = true;
  },
  // 业务建模提交成功回调
  submitSuccess() {
    this.businessDialog = false;
    this.requestTripDetailData(this.tripDetailData.id);
  },
  // 共享
  shareButtonClick() {
    this.personDilogShow = true;
    // this.requestShareTrip();
  },
  // 新增节点
  addNodeClick() {
    this.nodeTempData = null;
    this.supplementTempData = null;
    this.tripNodeShow = true;
  },
  // 删除行程单
  handleDeleteButton() {
    this.deleteTipsMessage = "确定要删除该行程单吗？";
    this.deleteTipsType = "deleteTrip";
    this.deleteTipsDialogVisible = true;
  },
  // 行程期间重叠提示
  reasonTipsButton(msg) {
    this.reasonTipsMessage = msg;
    this.reasonTipsDialogVisible = true;
  },
  // 保存行程单
  handleSaveButton() {
    const params = {
      id: this.tripDetailData.id,
      tripReason: this.tripDetailData.tripReason,
      delayStartDate: this.tripDetailData.delayStartDate,
      delayEndDate: this.tripDetailData.delayStartDate,
      addChangeReason: this.tripDetailData.addChangeReason
    };
    if (this.tripDetailData.approvalPassFlag !== "001") {
      params.tripStartDate = this.tripDetailData.tripStartDate;
      params.tripEndDate = this.tripDetailData.tripEndDate;
    }
    // if (!this.isCreateTrip) {
    //   params.id = this.tripDetailData.id;
    // }
    // console.log(params);

    this.requestSaveTrip(params);
  },
  // 提交行程单
  handleSubmitButton() {
    if (this.tripDetailData.tripStartDate === null) {
      this.$message.error("请选择行程开始日期");
      return;
    }
    if (this.tripDetailData.tripEndDate === null) {
      this.$message.error("请选择行程结束日期");
      return;
    }
    if (this.isExistOverTimeUnsubmitNode) {
      this.$message.error("行程单存在已过期的行程节点，请先删除!");
      return;
    }
    if (this.tripDetailData?.tripReason === undefined || this.tripDetailData?.tripReason?.length === 0) {
      this.$message.error("请输入行程事由");
      return;
    }
    if (this.tripDetailData?.tripReason?.length > 500) {
      this.$message.error("输入的行程事由在500字以内");
      return;
    }
    let isDelay = false;
    if (this.configResult !== "None") {
      isDelay = this.isExistDelay();
    }
    if (isDelay) {
      this.tripMemoDialogType = "tripDelay";
      this.tripMemoDialogMust = this.configResult === "Required";
      this.tripMemoDialogShow = true;
    } else {
      this.checkRepeatTripDate();
    }
    // this.$message.success(`提交行程单id:${this.tripDetailData.id}-tripNo:${this.tripDetailData.tripNo}`);
  },
  // 期间变更 显示提交按钮
  hasChangeDely(val) {
    this.hasChangeDate = val;
  },
  // 报销行程x
  async handleBaoXiaoButton() {
    await tripService.queryTripExpense({ id: this.tripDetailData.id });

    if (this.tripDetailData?.expenseId?.length === 0 && this.tripDetailData?.expenseFormId?.length === 0) {
      this.$message.error("请管理员配置行程报销单");
      return;
    }
    // bug37383
    const params = {
      id: this.tripDetailData.businessKey,
      templateId: this.tripDetailData.tripExpenseTempId,
      businessType: 2,
      tripId: this.tripDetailData.id,
      tripType: 1
    };
    this.businessParams = newBusinessDialogParams(params);
    this.businessDialog = true;
  },
  // 结束行程
  handleJieShuButton() {
    this.deleteTipsMessage = "确定要结束该行程单吗？";
    this.deleteTipsType = "endTrip";
    this.deleteTipsDialogVisible = true;
  },
  // 终止行程
  handleEndButton() {
    this.deleteTipsMessage = "确定要终止该行程单吗？";
    this.deleteTipsType = "closeTrip";
    this.deleteTipsDialogVisible = true;
  },
  // 行程节点 点击事件
  nodeCellClick(row) {
    this.nodeTempClickRow = row;
    this.nodeDetailShow = true;
  },
  // 节点操作事件
  operationButtonClick(node, obj) {
    if (obj.id === "bianji") {
      this.operationEditClick(node);
    } else if (obj.id === "zhuanyi") {
      this.operationChangeClick(node);
    } else if (obj.id === "shanchu") {
      this.operationDeleteClick(node);
    } else if (obj.id === "chongxinyuding") {
      this.operationReBookClick(node);
    } else if (obj.id === "tuipiao") {
      this.operationTuiClick(node);
    } else if (obj.id === "gaiqian") {
      this.operationGaiClick(node);
    } else if (obj.id === "quxiao") {
      this.operationCancelClick(node);
    }
  },
  // 编辑节点
  @request(true)
  async operationEditClick(item) {
    const params = {
      tripNodeType: "002",
      id: item.id
    };
    const nodeTempData = await this.requestTripNodeDetail(params);
    this.nodeTempData = cloneDeep(nodeTempData);
    this.supplementTempData = cloneDeep(nodeTempData);
    this.supplementShow = true;
  },
  // 转移节点
  async operationChangeClick(item) {
    this.transferTempNode = item;
    const canDeleteTrip = this.deleteButton(item);
    if (canDeleteTrip) {
      this.enConfirmDialogShow = true;
    } else {
      await this.requestCanTransferableTravelList();
    }
  },
  // 删除节点
  operationDeleteClick(item) {
    this.nodeTempData = item;
    this.deleteTipsMessage = "确定要删除该行程节点吗？";
    this.deleteTipsType = "deleteNode";
    this.deleteTipsDialogVisible = true;
  },
  // 重新预定
  operationReBookClick(item) {
    console.log(`重新预定节点:${item.name}`);
  },
  // 退票
  operationTuiClick(item) {
    console.log(`退票节点:${item.name}`);
  },
  // 改签
  operationGaiClick(item) {
    console.log(`改签节点:${item.name}`);
  },
  // 取消
  operationCancelClick(item) {
    this.nodeTempData = item;
    this.deleteTipsMessage = "确定要取消该行程节点吗？";
    this.deleteTipsType = "cancelNode";
    this.deleteTipsDialogVisible = true;
  },
  // 获取修改的补助金额
  async getInputConfirmValue(val) {
    this.tripDetailData.subsidyAmount = val;
    const params = {
      id: this.tripDetailData.id,
      subsidyAmount: val
    };
    await tripService.queryUpdateSubsidyAmount(params);
    this.tripDetailData = await tripService.queryTripDetail({ id: this.tripDetailData.id });
  },
  // 新增节点选择
  selectAddTripNode(val) {
    if (val === "supplement") {
      this.supplementShow = true;
    } else if (val === "flight") {
      this.$router.push({
        path: "/business-travel/flight",
        query: { tripId: this.tripDetailData.id }
      });
    } else if (val === "hotel") {
      this.$router.push({
        path: "/business-travel/hotel/entry",
        query: { tripId: this.tripDetailData.id }
      });
    } else if (val === "car") {
      this.$router.push({
        path: "/business-travel/car",
        query: { tripId: this.tripDetailData.id }
      });
    }
  },
  // 确定删除弹框事件
  confirmDeleteTipsDialog() {
    if (this.deleteTipsType === "deleteTrip") {
      // 删除行程单
      this.requestDeleteTrip(this.tripDetailData.id);
    } else if (this.deleteTipsType === "deleteNode") {
      // 删除节点
      const params = {
        id: this.nodeTempData.id,
        tripNodeType: this.nodeTempData.tripNodeType,
        tripId: this.nodeTempData.tripId
      };
      this.requestDeleteTripNodList(params);
    } else if (this.deleteTipsType === "cancelNode") {
      // 取消节点
      const params = {
        id: this.nodeTempData.id,
        tripNodeType: this.nodeTempData.tripNodeType
      };
      this.requestCancelTripNodList(params);
    } else if (this.deleteTipsType === "endTrip") {
      // 结束行程单
      this.requestEndTrip(this.tripDetailData.id);
    } else if (this.deleteTipsType === "closeTrip") {
      // 终止行程单
      this.requestCloseTrip(this.tripDetailData.id);
    }
  },
  // 确定行程期间重叠提示
  confirmReasonTipsDialog() {
    if (!this.tripDetailData.id) {
      this.requestSubmitTrip();
    }
    if (this.reaptCanSend) {
      this.addChangeReasonVisible = true;
    }
  }
};

// 弹框回调方法
const dialogMethods = {
  // 超标事由弹框 回调
  async getOverStandardMemo(memo) {
    const list = this.tripNodList("001", memo);
    const params = {
      tripNodList: JSON.stringify(list),
      tripNodeType: "002",
      type: this.supplementTempData.type
    };
    await this.checkSupplement(params);
  },
  // 行程期间变更事由弹窗 回调
  async getChangeReason(val) {
    this.addChangeReasonVisible = false;
    this.tripDetailData.delayAddReason = val;
    this.requestSubmitTrip();
  },
  // 转移节点时,是否删除行原程单 弹框回调(确定/取消)
  getEnComfirmValue(val) {
    if (val === true) {
      this.isDeleteTrip = true;
    } else {
      this.isDeleteTrip = false;
    }
    this.requestCanTransferableTravelList();
  },
  // 选择了关联的行程单 弹框回调
  async selectTripData(item) {
    const params = {
      sourceTripId: this.tripDetailData.id,
      sourceTripNodeType: this.transferTempNode.tripNodeType,
      sourceTripNodeId: this.transferTempNode.id,
      targetTripId: item.id,
      isDeleteTrip: this.isDeleteTrip ? 0 : 1
    };
    await tripService.queryTransferTravelNode(params);
    this.$message.success("转移成功");
    this.transferTempNode = null;
    if (this.isDeleteTrip) {
      this.backClick();
    } else {
      await this.requestTripDetailData(this.tripDetailData.id);
    }
  },
  // 新增补单节点 弹框回调
  addNewTripNode(data) {
    this.supplementTempData = data;
    this.supplementTempData.isEdit = false;
    const list = this.tripNodeDataList();
    const params = {
      tripNodList: JSON.stringify(list),
      tripNodeType: "002",
      type: this.supplementTempData.type
    };
    this.checkSupplement(params);
  },
  // 编辑补单节点 弹框回调
  updateTripNode(data) {
    this.supplementTempData = data;
    this.supplementTempData.isEdit = true;
    const list = this.tripNodeDataList();
    const params = {
      tripNodList: JSON.stringify(list),
      tripNodeType: "002",
      type: this.supplementTempData.type
    };
    this.checkSupplement(params);
  },
  // 行程事由输入弹框 确定后回调
  getTripDialogValue(data) {
    if (data.type === "tripChange") {
      // console.log(data);
    } else if (data.type === "tripDelay") {
      this.tripDetailData.delayAddReason = data.value;
      this.requestSubmitTrip();
    }
    this.tripMemoDialogType = "";
  },
  // 共享人回调
  async submitSelect() {
    this.personDilogShow = false;
    await this.requestShareTrip();
  }
};

// 接口请求方法
const requestMethods = {
  // 请求行程单详情数据
  @request(true)
  async requestTripDetailData(tripId) {
    this.tripDetailData = await tripService.queryTripDetail({ id: tripId });
    this.leftButtonList();
    const mdInfo = this.tripDetailData.mdInfo || this.tripDetailData.billInfo;
    if (mdInfo?.btnList.length > 0) {
      this.btnObj = {
        id: mdInfo.mainId,
        templateId: mdInfo.templateId,
        pId: mdInfo.pId
      };
      this.flowBtnList = mdInfo.btnList;
      const seeFlowInd = this.flowBtnList.findIndex((item) => item.btnKey === "seeflow");
      this.flowBtnList.splice(seeFlowInd, 1);
    }
    if (this.isExistDelay()) {
      this.requestTripComConfig();
    }
    this.tripResourceSetSearch();
  },
  // 查询流程和流程按钮展示权限
  // bug37650 bug37649
  async tripResourceSetSearch() {
    const rsp = await tripService.tripResourceSetSearch({ formType: 1, templateType: 2 });
    if (rsp.showFlow === 0) {
      if (this.tripDetailData?.businessKey?.length > 0) {
        this.requestTripMdData();// await
      }
    }
  },
  // 请求查询行程单配置数据
  async requestTripComConfig() {
    const params = {
      key_: "TRIPPLAN_CAUSE,TRIPPLAN_MUST",
      valType: "1"
    };
    const resultData = await tripService.queryComConfig(params);
    if (Number(resultData.TRIPPLAN_MUST) === 1) {
      this.configResult = "Required";
    } else if (Number(resultData.TRIPPLAN_CAUSE) === 1) {
      this.configResult = "Option";
    } else {
      this.configResult = "None";
    }
  },
  // 请求查询可转移的行程单列表
  @request(true)
  async requestCanTransferableTravelList() {
    const tripId = this.tripDetailData.id;
    this.transferableTravelList = await tripService.queryTransferableTravelList({ tripId });
    this.chooseItineraryShow = true;
  },
  // 请求检测超标
  @request(true)
  async checkSupplement(params) {
    const checkResult = await tripService.queryCheckSupplement(params);
    if (checkResult?.result?.isExcess === "000") {
      if (checkResult?.result?.standardFlag === "001") {
        // 超标 可提交
        this.overStandardVisible = true;
      } else if (checkResult?.result?.standardFlag === "000") {
        // 禁止提交
        this.$message.error("行程超标, 禁止提交");
      } else {
        const list = this.tripNodeDataList("000", "");
        await this.submitNodeListData(list);
      }
    } else {
      const list = this.tripNodeDataList("000", "");
      await this.submitNodeListData(list);
    }
    // console.log(checkResult);
  },
  // (超标校验后)请求提交节点数据
  async submitNodeListData(list) {
    if (this.supplementTempData.isEdit) {
      const params = {
        tripNode: JSON.stringify(list[0])
      };
      await this.requestUpdateTripNodList(params);
    } else {
      const params = {
        addTripNodeList: JSON.stringify(list)
      };
      await this.requestAddTripNodList(params);
    }
  },
  // 请求新增行程节点
  async requestAddTripNodList(params) {
    if (this.tripDetailData?.id?.length > 0) {
      params.tripNo = this.tripDetailData?.tripNo;
      params.id = this.tripDetailData.id;
    }
    const rsp = await tripService.querySaveTripNode(params);
    this.nodeTempData = null;
    this.supplementTempData = null;
    if (rsp?.id) {
      if (this.isCreateTrip) {
        this.tripDetailData.id = rsp.id;
        this.handleSaveButton();
      } else {
        this.$message.success("保存成功");
        await this.requestTripDetailData(rsp.id);
      }
    }
  },
  // 请求更新行程节点
  async requestUpdateTripNodList(params) {
    await tripService.queryEditTripNode(params);
    this.$message.success("保存成功");
    this.nodeTempData = null;
    this.supplementTempData = null;
    await this.requestTripDetailData(this.tripDetailData.id);
  },
  // 请求删除行程节点
  async requestDeleteTripNodList(params) {
    await tripService.queryDeleteTripNode(params);
    this.$message.success("删除成功");
    this.nodeTempData = null;
    this.supplementTempData = null;
    await this.requestTripDetailData(this.tripDetailData.id);
  },
  // 请求取消行程节点
  async requestCancelTripNodList(params) {
    await tripService.queryCancelTripNode(params);
    this.$message.success("取消成功");
    this.nodeTempData = null;
    this.supplementTempData = null;
    await this.requestTripDetailData(this.tripDetailData.id);
  },
  // 请求查看节点详情
  async requestTripNodeDetail(params) {
    const nodeDetail = await tripService.queryTripNodeDetail(params);
    // console.log(JSON.stringify(nodeDetail));
    return nodeDetail;
  },
  // 请求查询业务数据(配合审批流使用?)
  async requestTripMdData() {
    this.mdDetailData = await tripService.queryMdSearchDataInfo({ id: this.tripDetailData.businessKey });
    if (this.mdDetailData?.mdInfo?.btnList?.length > 0) {
      // this.flowBtnList = this.mdDetailData?.mdInfo?.btnList;
      for (let i = 0; i < this.mdDetailData?.mdInfo?.btnList.length; i++) {
        const obj = this.mdDetailData?.mdInfo?.btnList[i];
        if (obj.btnKey === "seeflow") {
          this.isShowFlow = true;
          this.isExpose = false;
          return;
        }
      }
    }
  },
  // 请求查询审批流程
  @request(true)
  async requestTripFlow() {
    const flowList = await tripService.queryWfQueryFlowDetail({ businessKey: this.tripDetailData.businessKey });
    this.flowList = flowList.sort();
  },
  // 请求保存行程单
  async requestSaveTrip(params) {
    await tripService.querySaveTrip(params);
    this.$message.success("保存成功");
    if (!this.isCreateTrip) {
      this.backClick();
    } else {
      this.requestTripDetailData(this.tripDetailData.id);
    }
  },
  // 请求删除行程单
  async requestDeleteTrip(id) {
    await tripService.queryDeleteTrip({ id });
    this.$message.success("删除成功");
    this.backClick();
  },
  // 请求结束行程单
  async requestEndTrip(id) {
    await tripService.queryEndTrip({ id });
    this.$message.success("操作成功");
    await this.requestTripDetailData(this.tripDetailData.id);
  },
  // 请求终止行程单
  async requestCloseTrip(id) {
    await tripService.queryCloseTrip({ id });
    this.$message.success("操作成功");
    await this.requestTripDetailData(this.tripDetailData.id);
  },
  // 校验行程期间是否有重复
  async checkRepeatTripDate() {
    const params = {
      id: this.tripDetailData.id,
      tripStartDate: this.tripDetailData.tripStartDate,
      tripEndDate: this.tripDetailData.tripEndDate
    };
    const data = await tripService.checkRepeatTripDate(params);
    // result 0 有重叠 1无重叠
    if (parseInt(data.ifRepeat, 10) === 1 && parseInt(data.result, 10) === 0) {
      // 禁止提交并且 有重叠
      this.reasonTipsButton(data.repeatText);
    } else if (parseInt(data.ifRepeat, 10) === 0 && parseInt(data.result, 10) === 0 && data.repeatText) {
      // 允许提交 有重叠
      this.reasonTipsButton(data.repeatText);
      this.reaptCanSend = true;
    } else {
      this.requestSubmitTrip();
    }
  },
  // 请求提交行程单
  async requestSubmitTrip() {
    const params = {
      id: this.tripDetailData.id,
      tripReason: this.tripDetailData.tripReason,
      delayStartDate: this.tripDetailData.delayStartDate,
      delayEndDate: this.tripDetailData.delayEndDate,
      tripStartDate: this.tripDetailData.tripStartDate,
      tripEndDate: this.tripDetailData.tripEndDate,
      addChangeReason: this.tripDetailData.delayAddReason
    };
    const rspData = await tripService.querySubmitTrip(params);
    const formId = rspData.formId;
    const businessKey = rspData.businessKey;
    const templateType = rspData.templateType;
    const jumpBus = rspData.jumpBus;
    if (jumpBus === "000") {
      if (formId?.length === 0) {
        this.$message.success("提交成功");
        if (Number(templateType) !== 3) {
          this.backClick();
        }
        return;
      }
      if (rspData.errorText) {
        this.$message.success(rspData.errorText);
      }
      this.businessDialogCick(businessKey, 2, false, formId);
      // if (Number(templateType) !== 2) {
      //   this.$message.success(`需跳转业务建模-formId:${formId}-businessKey:${businessKey}`);
      // } else if (Number(templateType) !== 1) {
      //   this.$message.success(`需跳转单据-formId:${formId}-businessKey:${businessKey}`);
      // }
    } else {
      // 刷新当前页面
      await this.requestTripDetailData(this.tripDetailData.id);
    }
  },
  // 请求共享行程单
  @request(true)
  async requestShareTrip() {
    let toShareUserIds = "";
    for (let i = 0; i < this.sharePersonList.length; i++) {
      const obj = this.sharePersonList[i];
      if (toShareUserIds.length > 0) {
        toShareUserIds = `${toShareUserIds},${obj.id}`;
      } else {
        toShareUserIds = obj.id;
      }
    }
    const params = {
      refId: this.tripDetailData.id,
      tripType: "099",
      toShareUser: toShareUserIds
    };
    await tripService.queryShareTrip(params);
    this.$message.success("共享成功");
  },
  btnHandle(btn) {
    this.$refs.businessFunction.triggerButtonFunction(btn);
  }
};

export default {
  data() {
    return {
      tripOccupyShow: false, // 预算占用弹框
      nodeDetailShow: false, // 行程节点详情弹框
      applyShow: false, // 预借差旅费弹框
      rTicketDialogShow: false, // 退票弹框
      businessDialog: false, // 业务建模弹框
      personDilogShow: false, // 共享人选择弹框
      sharePersonList: [],
      shareTransferConfig: {
        isMulti: true, // 是否多选
        hideRight: false,
        // showHead: true, // 右侧选中的数据是否需要显示头像
        // imgPath: "", // 数据的头像承载字段 图片地址所在的字段
        dependData: {
          requestService: tripService, // 引入服务名称
          requestFn: "requestBdQueryPersonDeptTree", // 服务中方法
          requestParams: {
            id: "0", isNeedLogo: 0, type: "", pageNo: 1, pageSize: 20
          } // 请求参数
        },
        // 设置默认label、value取值参数  参考en-tree中defaultProps
        defaultProps: {
          label: "name",
          value: "id"
        }
      },

      isCreateTrip: false, // 新建行程单
      isShowFlow: false, // 显示审批流程
      isExpose: false, // 审批流展开/收起
      rightButtonList: [], // 右上角按钮
      amountEditVisible: false, // 补助金额弹框
      tripNodeShow: false, // 行程节点弹框

      chooseItineraryShow: false, // 行程选择(新建行程)
      transferableTravelList: [], // 可转移行程列表数据
      transferTempNode: {}, // 转移节点临时数据
      isDeleteTrip: false, // 转移时是否删除行程单
      enConfirmDialogShow: false, // 转移时是否删除行程单弹框

      tripMemoDialogShow: false, // 行程单事由弹框
      tripMemoDialogType: "", // 弹框类型
      tripMemoDialogMust: false, // 是否空校验

      deleteTipsDialogVisible: false, // 删除弹框
      deleteTipsMessage: "确定要删除该行程单吗？",
      deleteTipsType: "deleteTrip", // 区分类型

      reasonTipsDialogVisible: false, // 行程期间重叠提醒文字
      reasonTipsMessage: "",

      overStandardVisible: false, // 超标弹框
      addChangeReasonVisible: false, // 行程期间变更事由弹窗
      reaptCanSend: false, // 标记形成期间重复克提交

      supplementShow: false, // 新增补单节点弹框
      supplementTempData: {}, // 补单临时数据
      nodeTempData: {}, // 临时节点数据
      nodeTempClickRow: {}, // 节点点击数据

      configResult: "", // 行程单配置结果数据
      businessParams: {}, // 业务建模弹框传参
      tripDetailData: {}, // 行程详情
      flowBtnList: [],
      hasChangeDate: false, // 行程期间是否变更
      btnObj: {},

      mdDetailData: {}, // 业务建模数据

      flowList: [] // 审批流程数据
    };
  },
  watch: {
    // 行程单数据
    // tripDetailData(val) {
    //   console.log(val);
    // },
    // 审批流展开监听
    isExpose(val) {
      if (val === true) {
        if (this.flowList?.length === 0) {
          // this.requestTripFlow();
        }
      }
    }
  },
  activated() {
    this.clearAllData();
    const obj = this.$route.query;
    if (obj && obj?.tripId?.length > 0) {
      this.isCreateTrip = false;
      this.requestTripDetailData(obj.tripId);
    } else {
      this.isCreateTrip = true;
    }
  },
  computed: {
    // 新增节点按钮
    addButton() {
      if (this.isCreateTrip) {
        return true;
      }
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "000"
        || this.tripDetailData?.tripStatus === "002"
        || this.tripDetailData?.tripStatus === "003"
        || this.tripDetailData?.tripStatus === "004"
        || this.tripDetailData?.tripStatus === "005") {
        return true;
      }
      return false;
    },
    // 保存按钮
    saveButton() {
      if (this.isCreateTrip) {
        return true;
      }
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "000") {
        return true;
      }
      return false;
    },
    // 提交按钮
    submitButton() {
      if (this.isCreateTrip) {
        return true;
      }
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "000"
          || this.tripDetailData?.tripStatus === "002") {
        return true;
      }
      if (this.hasChangeDate) {
        return true;
      }
      if (this.tripDetailData?.tripStatus === "003"
      || this.tripDetailData?.tripStatus === "004"
      || this.tripDetailData?.tripStatus === "005") {
        for (let j = 0; j < this.allNodeList.length; j++) {
          const node = this.allNodeList[j];
          if (node.approvalPassFlag === "000") {
            return true;
          }
        }
      }
      return false;
    },
    // 底部报销按钮
    baoXiaoButton() {
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "005" && this.tripDetailData?.tempStartDate?.length === 0) {
        return true;// && this.tripDetailData?.tempStartDate?.length === 0 (APP加了这句)
      }
      if (this.tripDetailData?.tripStatus === "008") {
        return true;
      }
      return false;
    },
    // 结束按钮
    jieShuButton() {
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "003"
        || this.tripDetailData?.tripStatus === "004") {
        let isCanJS = true;
        for (let j = 0; j < this.allNodeList.length; j++) {
          const node = this.allNodeList[j];
          if (!(node.status === "001" || node.status === "004" || node.status === "006" || node.status === "008" || node.status === "010" || node.status === "017")) {
            isCanJS = false;
            break;
          }
        }
        return isCanJS;
      }
      return false;
    },
    // 终止按钮
    endButton() {
      if (!enEqual(this.tripDetailData.createId, Store.getters.userInfo.userId)) {
        return false;
      }
      if (this.tripDetailData?.tripStatus === "002") {
        return true;
      }
      return false;
    },
    // 是否有节点
    noNodeMode() {
      let hasNode = false;
      for (let i = 0; i < this?.tripDetailData?.tripNodeList?.length; i++) {
        const nodeItem = this?.tripDetailData?.tripNodeList[i];
        if (nodeItem?.tripList?.length > 0) {
          hasNode = true;
          break;
        }
      }
      return !hasNode;
    },
    // 显示补助金额
    subsidyMoney() {
      if (this.tripDetailData?.tripStatus === "005"
        || this.tripDetailData?.tripStatus === "006"
        || this.tripDetailData?.tripStatus === "008"
        || this.tripDetailData?.tripStatus === "009") {
        return true;
      }
      return false;
    },
    // 可否编辑补助金额
    editSubsidy() {
      if (this.tripDetailData?.tripStatus === "006"
        || this.tripDetailData?.tripStatus === "009") {
        return false;
      }
      return true;
    },
    // 共享描述
    shareUserDetail() {
      return `由${this.tripDetailData.shareUserList[0].name}共享`;
    },
    // 所有节点数据
    allNodeList() {
      const allList = [];
      if (this.tripDetailData.tripNodeList) {
        for (let i = 0; i < this.tripDetailData.tripNodeList.length; i++) {
          const item = this.tripDetailData.tripNodeList[i];
          allList.push(...item.tripList);
        }
      }
      return allList;
    },
    // 检查行程节点是否有效
    isExistOverTimeUnsubmitNode() {
      let ret = false;
      for (let i = 0; i < this.allNodeList.length; i++) {
        const node = this.allNodeList[i];
        if (this.isOverTimeUnsubmitNode(node)) {
          ret = true;
          break;
        }
      }
      return ret;
    },
    // 是否是共享的行程单
    isHasShareUserList() {
      if (this.tripDetailData?.shareUserList?.length > 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...dataAndUIMethods,
    ...requestMethods,
    ...dialogMethods,
    ...actionMethods,
    ...checkMethods
  }
};
