<template>
  <div class="flight-container" style="overflow-y: auto;">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
    >
      <!-- 航班信息 -->
      <sectionTitle section-name="航班信息" />

      <!-- 航班号 -->
      <el-form-item prop="code">
        <el-input v-model="form.code" clearable placeholder="航班号" maxlength="20">
          <en-icon
            slot="prefix"
            name="jipiaoyuding-xianxing"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <!-- 舱位 -->
      <el-form-item prop="classType">
        <el-select v-model="form.classType" placeholder="请选择舱位">
          <en-icon
            slot="prefix"
            name="cangweidengji-wodehangcheng"
            size="16px"
            class="en-prefix"
          ></en-icon>
          <el-option
            v-for="item in classTypeList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>

      <separatorLine />

      <!-- 时间地点 -->
      <sectionTitle section-name="时间地点" />

      <!-- 出发日期  -->
      <el-form-item prop="arrivalDate">
        <div style="position: relative;">
          <el-date-picker
            v-model="form.arrivalDate"
            type="date"
            placeholder="出发日期"
          >
          </el-date-picker>
          <en-icon
            name="riqi-shanglvguanli"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <!-- 出发城市  -->
      <el-form-item prop="startCity">
        <div style="position: relative;">
          <en-city
            v-model="form.startCity"
            placeholder="出发城市"
            @input="startCityInfo"
          ></en-city>
          <en-icon
            name="chufachengshi-budan"
            size="16px"
            class="city-prefix"
          ></en-icon>
        </div>
      </el-form-item>

      <!-- 到达城市 -->
      <el-form-item prop="arrivalCity">
        <div style="position: relative;">
          <en-city
            v-model="form.arrivalCity"
            placeholder="到达城市"
            @input="arrivalCityInfo"
          ></en-city>
          <en-icon
            name="daodachengshi-budan"
            size="16px"
            class="city-prefix"
          ></en-icon>
        </div>

        <!-- <el-input v-model="form.arrivalCity" clearable placeholder="到达城市">
          <en-icon
            slot="prefix"
            name="daodachengshi-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input> -->
      </el-form-item>

      <separatorLine />

      <!-- 人员信息 -->
      <sectionTitle section-name="乘机人员" />
      <userList :user-list.sync="form.userList" />
      <separatorLine />

      <!-- 合计金额 -->
      <sectionTitle section-name="合计金额" />
      <el-form-item prop="totalAmount">
        <el-input
          v-model="form.totalAmount"
          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
          clearable placeholder="合计金额"
          maxlength="10"
        >
          <en-icon
            slot="prefix"
            name="hejijine-budan"
            size="16px"
            class="en-prefix"
          ></en-icon>
        </el-input>
      </el-form-item>

      <separatorLine />

      <!-- 事项说明 -->
      <sectionTitle section-name="事项说明" />
      <el-form-item prop="explain">
        <div style="position: relative;">
          <el-input
            v-model="form.explain"
            type="textarea"
            placeholder="事项说明"
            maxlength="100"
          >
          </el-input>
          <en-icon
            name="shixiangshuoming-budan"
            size="16px"
            class="custom-prefix"
          ></en-icon>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Store from "@/store";
import enCity from "../../components/en-city";
import separatorLine from "../components/separator-line";
import sectionTitle from "../components/section-title";
import userList from "../components/user-list";

export default {
  name: "Flight",
  components: {
    separatorLine,
    sectionTitle,
    userList,
    enCity
  },
  props: {
    flightData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      classTypeList: [
        {
          id: "000",
          name: "经济舱"
        },
        {
          id: "001",
          name: "公务舱"
        },
        {
          id: "002",
          name: "头等舱"
        }
      ],
      // form数据
      form: {
        type: "000",
        code: "",
        classType: "000",
        arrivalDate: "",
        startCity: {
          enCityName: ""
        },
        arrivalCity: {
          enCityName: ""
        },
        totalAmount: "",
        explain: "",
        userId: "",
        userList: []
      },
      rules: {
        // 添加验证
        code: [
          {
            required: true,
            message: "请输入航班号",
            trigger: "blur"
          },
          {
            max: 20,
            message: "最多输入20个字符",
            trigger: "blur"
          }
        ],
        classType: [
          {
            required: true,
            message: "请选择舱位",
            trigger: "blur"
          }
        ],
        arrivalDate: [
          {
            required: true,
            message: "请选择出发日期",
            trigger: "blur"
          }
        ],
        startCity: [
          // {
          //   required: true,
          //   message: "输入出发城市",
          //   trigger: "blur"
          // }
          // {
          //   max: 100,
          //   message: "最多输入100个字符",
          //   trigger: "blur"
          // }
        ],
        arrivalCity: [
          // {
          //   required: true,
          //   message: "输入到达城市",
          //   trigger: "blur"
          // }
          // {
          //   max: 100,
          //   message: "最多输入100个字符",
          //   trigger: "blur"
          // }
        ],
        totalAmount: [
          {
            required: true,
            message: "输入合计金额",
            trigger: "blur"
          }
          // ,
          // {
          //   required: true,
          //   message: "请填写概率值，仅限两位小数"
          // },
          // {
          //   pattern: /^\d{1,2}(\.\d{1,2})?$/,
          //   message: "概率权重精确到两位小数点，勿超过100"
          // }
        ],
        explain: [
          {
            required: true,
            message: "输入事项说明",
            trigger: "blur"
          },
          {
            max: 500,
            message: "最多输入500个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    if (this.flightData) {
      this.form = this.flightData;
    } else {
      this.form.userList.push({
        userId: Store.getters.userInfo.userId,
        name: Store.getters.userInfo.name
      });
    }
  },
  beforeDestroy() {
    this.$emit("update:flightData", this.form);
  },
  methods: {
    parentHandleClick(val) {
      console.log(val);
      /* eslint-disable */
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.userList?.length === 0) {
            this.$alert("请添加人员");
          } else {
            this.$emit("commitData", this.form);
          }
        } else {
          this.$alert("请根据提示完成对应内容的填写或选择");
          return false;
        }
      });
      /* eslint-enable */
    },
    startCityInfo(info) {
      this.form.startCity = info;
    },
    arrivalCityInfo(info) {
      this.form.arrivalCity = info;
    }
  }
};
</script>

<style lang="scss" scoped>
.flight-container {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  .custom-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .city-prefix {
    position: absolute;
    top: 7px;
    left: 11px;
    fill: #a9b5c6;
  }
  .en-prefix {
    margin-top: 7px;
    margin-right: 6px;
    fill: #a9b5c6;
  }
  & /deep/ .en-city-panel {
    width: 820px;
    left: 0;
  }
  & /deep/ .el-input,
  .el-select {
    margin-bottom: 14px;
  }
  & /deep/ .el-form-item {
    min-height: 46px;
    margin-left: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .el-form-item__label {
      display: none;
    }
    .el-input {
      margin-bottom: 14px;
    }
  }
  & /deep/ .el-input__inner,
  .el-textarea__inner {
    width: 820px;
    padding-left: 12px;
  }
  & /deep/ .el-input__icon {
    line-height: 32px !important;
  }
  & /deep/ .el-date-editor {
    width: 820px;
    .el-input__prefix {
      display: none;
    }
  }
  & /deep/ .el-date-editor > .el-input__prefix {
    left: 6px !important;
  }
  & /deep/ .el-input__inner {
    padding-left: 34px;
  }
  & /deep/ .el-textarea {
    margin-bottom: 20px;
    width: 820px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #b9c4d2;
    .el-textarea__inner {
      padding-left: 34px;
    }
  }
}
</style>
