var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-apply-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogApplyVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogApplyVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-content-contain",
              style: _vm.contentWHStyle,
            },
            [
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c(
                "div",
                { on: { click: _vm.closeClick } },
                [
                  _c("en-icon", {
                    staticClass: "close-icon",
                    style: { fill: "#A9B5C6" },
                    attrs: { name: "guanbi-danchuang", size: "14px" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "operation-contain" },
                [
                  _c(
                    "en-button",
                    {
                      attrs: {
                        type: "text",
                        "icon-color": "#3e90fe",
                        icon: "yiyouhangcheng",
                      },
                      on: { click: _vm.operationTripClick },
                    },
                    [_vm._v(" 行程单 ")]
                  ),
                  _c(
                    "en-button",
                    {
                      attrs: {
                        type: "text",
                        "icon-color": "#3e90fe",
                        icon: "dayin-kuaijiecaozuo",
                      },
                      on: { click: _vm.operationPrintingClick },
                    },
                    [_vm._v(" 打印 ")]
                  ),
                  _c(
                    "en-button",
                    {
                      attrs: {
                        type: "text",
                        "icon-color": "#A9B5C6",
                        icon: "niantiedan-wodehangcheng",
                      },
                      on: { click: _vm.operationPasteClick },
                    },
                    [_vm._v(" 粘贴单 ")]
                  ),
                  _c(
                    "en-button",
                    {
                      attrs: {
                        type: "text",
                        "icon-color": "#FFAD2C",
                        icon: "gongxiang-wodehangcheng",
                      },
                      on: { click: _vm.operationCommunicateClick },
                    },
                    [_vm._v(" 沟通 ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "body-contain" }, [
                _c("div", { staticClass: "detail-contain" }, [
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(" 姓名："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 张三 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(" 部门："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 技术部 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(" 公司："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 湖南英富莱网络技术有限公司 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(" 单号："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" BD201908070002 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title" }, [
                    _vm._v(" 时间："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 2019-10-28 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title detail-one-line" }, [
                    _vm._v(" 事由："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 报销差旅费 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-title detail-one-line" }, [
                    _vm._v(" 内容："),
                    _c("div", { staticClass: "detail-content" }, [
                      _vm._v(" 出差预借差旅费1000元 "),
                    ]),
                  ]),
                ]),
                _c("div", { ref: "personDiv", staticClass: "person-contain" }, [
                  _c("div", { staticClass: "person-title" }, [
                    _vm._v(" 发起审批 "),
                  ]),
                  _c("div", { staticClass: "person-body-contain" }, [
                    _c(
                      "div",
                      { staticClass: "person-list-contain" },
                      [
                        _vm._l(_vm.personList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "person-item-contain",
                            },
                            [
                              _c("en-user-logo", {
                                staticClass: "logo",
                                attrs: { "user-name": item.name, size: "34" },
                              }),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteUser(item)
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    staticClass: "user-delete",
                                    style: { fill: "#F76B6B" },
                                    attrs: { name: "shibai", size: "14px" },
                                  }),
                                ],
                                1
                              ),
                              _vm.personList.indexOf(item) !==
                              _vm.personList.length - 1
                                ? _c("div", { staticClass: "jiantou" }, [
                                    _vm._v(" → "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _c("div", { staticClass: "add-item-contain" }, [
                          _c(
                            "div",
                            {
                              staticClass: "add-contain",
                              on: { click: _vm.addUserClick },
                            },
                            [
                              _c("en-icon", {
                                staticClass: "add-icon",
                                style: { fill: "#3e90fe" },
                                attrs: {
                                  name: "tianjia-anniutoubu",
                                  size: "10px",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "add-text" }, [
                            _vm._v(" 添加 "),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "flow-contain" }, [
                  _c("div", { staticClass: "flow-title" }, [
                    _vm._v(" 审批流程 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flow-list-contain" },
                    _vm._l(_vm.flowList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "flow-item-contain" },
                        [
                          _c(
                            "div",
                            { staticClass: "item-left-contain" },
                            [
                              _c("div", { staticClass: "left-line" }),
                              _c("en-icon", {
                                staticClass: "left-icon",
                                attrs: { name: "guize-liebiao", size: "16px" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item-right-contain" },
                            [
                              _c("en-user-logo", {
                                staticClass: "logo",
                                attrs: { "user-name": "测试", size: "40" },
                              }),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(" " + _vm._s(item.time) + " "),
                              ]),
                              _c("div", { staticClass: "approval" }, [
                                _vm._v(" " + _vm._s(item.approval) + " "),
                              ]),
                              _c("div", { staticClass: "reason" }, [
                                _vm._v(" " + _vm._s(item.reason) + " "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "bottom-contain" },
                [
                  _c(
                    "en-button",
                    { staticClass: "button", on: { click: _vm.bottomClick } },
                    [_vm._v(" 收回 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.contactsVisible
        ? _c("enContacts", {
            attrs: { "dialog-visible": _vm.contactsVisible },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.contactsVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.contactsVisible = $event
              },
              getEnContactConfirm: _vm.getSelectContact,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }