var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotel-container", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c("sectionTitle", { attrs: { "section-name": "酒店信息" } }),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c(
                "el-input",
                {
                  attrs: { clearable: "", placeholder: "酒店名称" },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                },
                [
                  _c("en-icon", {
                    staticClass: "en-prefix",
                    attrs: {
                      slot: "prefix",
                      name: "jiudianyuding-xianxing",
                      size: "16px",
                    },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("separatorLine"),
          _c("sectionTitle", { attrs: { "section-name": "时间地点" } }),
          _c("el-form-item", { attrs: { prop: "arrivalDate" } }, [
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", placeholder: "入住日期" },
                  model: {
                    value: _vm.form.arrivalDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "arrivalDate", $$v)
                    },
                    expression: "form.arrivalDate",
                  },
                }),
                _c("en-icon", {
                  staticClass: "custom-prefix",
                  attrs: { name: "riqi-shanglvguanli", size: "16px" },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { prop: "departureDate" } }, [
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", placeholder: "离店日期" },
                  model: {
                    value: _vm.form.departureDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "departureDate", $$v)
                    },
                    expression: "form.departureDate",
                  },
                }),
                _c("en-icon", {
                  staticClass: "custom-prefix",
                  attrs: { name: "lidianriqi", size: "16px" },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { prop: "arrivalCity" } }, [
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("en-city", {
                  attrs: { placeholder: "入住城市" },
                  model: {
                    value: _vm.form.arrivalCity,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "arrivalCity", $$v)
                    },
                    expression: "form.arrivalCity",
                  },
                }),
                _c("en-icon", {
                  staticClass: "city-prefix",
                  attrs: { name: "daodachengshi-budan", size: "16px" },
                }),
              ],
              1
            ),
          ]),
          _c("separatorLine"),
          _c("sectionTitle", { attrs: { "section-name": "入住人员" } }),
          _c("userList", {
            attrs: { "user-list": _vm.form.userList },
            on: {
              "update:userList": function ($event) {
                return _vm.$set(_vm.form, "userList", $event)
              },
              "update:user-list": function ($event) {
                return _vm.$set(_vm.form, "userList", $event)
              },
            },
          }),
          _c("separatorLine"),
          _c("sectionTitle", { attrs: { "section-name": "合计金额" } }),
          _c(
            "el-form-item",
            { attrs: { prop: "totalAmount" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    onkeyup: "this.value=this.value.replace(/[^\\d.]/g,'');",
                    clearable: "",
                    placeholder: "合计金额",
                    maxlength: "10",
                  },
                  model: {
                    value: _vm.form.totalAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "totalAmount", $$v)
                    },
                    expression: "form.totalAmount",
                  },
                },
                [
                  _c("en-icon", {
                    staticClass: "en-prefix",
                    attrs: {
                      slot: "prefix",
                      name: "hejijine-budan",
                      size: "16px",
                    },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("separatorLine"),
          _c("sectionTitle", { attrs: { "section-name": "事项说明" } }),
          _c("el-form-item", { attrs: { prop: "explain" } }, [
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "事项说明",
                    maxlength: "100",
                  },
                  model: {
                    value: _vm.form.explain,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "explain", $$v)
                    },
                    expression: "form.explain",
                  },
                }),
                _c("en-icon", {
                  staticClass: "custom-prefix",
                  attrs: { name: "shixiangshuoming-budan", size: "16px" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }