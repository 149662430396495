<template>
  <div class="trip-summary-container">
    <div class="title-row">
      <span class="title">行程概要</span>
      <!-- <span class="tripNo">{{ tripData.tripNo }}</span>-->
    </div>

    <div v-if="isCanEdit">
      <div class="date-row">
        <span class="title">行程期间</span>
        <div style="position: relative;">
          <el-date-picker
            v-model="tripDateRange"
            class="date-picker"
            type="daterange"
            range-separator="一"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <en-icon name="jipiaorili" size="14px" :style="{ fill: '#A9B5C6' }" class="before-icon"></en-icon>
        </div>
      </div>

      <div class="reason-row">
        <span class="title">行程事由</span>
        <el-input v-model="tripData.tripReason" type="textarea" autosize placeholder="请输入行程事由" class="reason-input" :class="{ wide: !hasChangeTrip }" :maxlength="500" show-word-limit>
        </el-input>
      </div>
      <!--- 按道理不存在这种情况-->
      <div v-if="hasChangeDate" class="date-row" style="margin-top: 16px;">
        <span class="title">期间变更</span>
        <div style="position: relative; display:inline-block;">
          <el-date-picker
            v-model="tripDateRange"
            class="date-picker"
            type="daterange"
            range-separator="一"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <en-icon name="jipiaorili" size="14px" :style="{ fill: '#A9B5C6' }" class="before-icon"></en-icon>

          <!-- <el-popover popper-class="custom-trip-summary-popover" placement="bottom-start" trigger="hover" :disabled="false">
            <div class="tips-trip-change-popover">
              <div v-for="item in tripData.delyre" :key="item.id" class="content-contain">
                <div class="dian"></div>
                <div class="time">
                  2020-03-20 12:30
                </div>
                <div class="text">
                  变更至 2020-05-30 / 2020-06-01：我的变更原因
                </div>
              </div>
            </div>
            <en-icon slot="reference" name="xiaoxi" size="12px" :style="{ fill: '#F7BF27' }" class="tips-icon"></en-icon>
          </el-popover> -->
        </div>
      </div>
    </div>

    <div v-else class="look-mode-contain">
      <div class="line-contain">
        <span class="left-title">行程期间</span>

        <!--
        <el-popover
          popper-class="custom-trip-summary-popover"
          placement="bottom-start"
          trigger="hover"
          :disabled="false"
        >
          <div class="tips-trip-summary-popover">
            <span>
              延期至2019-07-30：合同细节未确定，须延期2天。合同细节未确定，须延期2天合同细节未确定，
              须延期2天。合同细节未确定，须延期2天延期至2019-07-30：合同细节未确定，须延期2天。合同
              细节未确定，须延期2天合同细节未确定，须延期2天。合同细节未确定，须延期2天
            </span>
          </div>
          <span slot="reference" class="content">{{
            tripData.tripStartDate + " / " + tripData.tripEndDate
          }}</span>
        </el-popover>
        -->
        <span class="content">{{ tripData.tripStartDate + " / " + tripData.tripEndDate }}</span>
        <en-icon name="iconbianji1" size="14px" class="edit-time" @click.native="changeTime" v-if="canEidtTime"></en-icon>

        <!-- UI修改,暂时移除
        <en-trip-bill-status
          :is-dark="true"
          :status="Number(tripData.tripStatus)"
          class="status"
        ></en-trip-bill-status>
        -->
      </div>
      <div class="line-contain reason-line">
        <span class="left-title">行程事由</span>
        <span class="content ">{{ tripData.tripReason }}</span>
      </div>
      <!-- 行程期间弹框 -->
      <timeChangeDialog v-if="timeChangeVisible" :dialog-visible.sync="timeChangeVisible" :mainTime="tripDelyDateRange" @updateTime="updateCTime"></timeChangeDialog>
      <div v-if="hasChangeDate" class="line-contain" style="padding-top:16px;">
        <span class="left-title" style="margin-top:0px;">期间变更</span>
        <div style="position: relative; display:inline-block;">
          <el-date-picker
            v-model="tripDelyDateRange"
            class="date-picker"
            type="daterange"
            range-separator="一"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="changeDatePicker"
          >
          </el-date-picker>
          <!-- <el-date-picker
            v-model="tripDelyDateRange"
            class="date-picker"
            type="daterange"
            :disabled="!editChangeTrip"
            range-separator="一"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker> -->
          <en-icon name="jipiaorili" size="14px" :style="{ fill: '#A9B5C6' }" class="before-icon"></en-icon>

          <el-popover v-if="tripData.delayReason.length > 0" popper-class="custom-trip-summary-popover" placement="bottom-start" trigger="hover" :disabled="false">
            <div class="tips-trip-change-popover">
              <div v-for="item in tripData.delayReason" :key="item.id" class="content-contain">
                <div class="dian"></div>
                <div class="time">
                  {{ item.modifyTime }}
                  <!-- {{ `${delayPrefix(item)}:` }} -->
                </div>
                <div class="text">
                  {{ item.changeReason }}
                </div>
              </div>
            </div>
            <en-icon slot="reference" name="xiaoxi" size="12px" :style="{ fill: '#F7BF27' }" class="tips-icon"></en-icon>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timeChangeDialog from "../trip-timeChange-dialog";

// import enTripBillStatus from "../../../components/en-trip-bill-status";
// import { tripData } from "../../data/tripDetail";
// import { isToday, format, isYesterday, isTomorrow } from "date-fns";
export default {
  name: "TripSummary",
  components: {
    timeChangeDialog
    // enTripBillStatus
  },
  props: {
    tripData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isCanEdit: true, // 行程区间和行程事由是否可编辑
      hasChangeTrip: false, // 是否有期间变更,
      timeChangeVisible: false, // 形成期间弹窗
      editChangeTrip: false, // 期间变更是否可以编辑
      tripDateRange: [new Date(), new Date(Number(new Date()) + 86400000)],
      tripDelyDateRange: [new Date(), new Date()],
      canEidtTime: false,
      changeDate: [],
      hasChangeDate: false
    };
  },
  watch: {
    tripData(val) {
      console.log("tripData", val);
      this.hasChangeDate = false;
      const f = val.delayEndDate === val.tripEndDate && val.delayStartDate === val.tripStartDate;
      if (val.id) {
        if (["003", "004", "005"].indexOf(val.tripStatus) > -1 && f && val.tempStartDate === "") {
          this.canEidtTime = true;
        }
        this.tripDateRange = [new Date(val.tripStartDate), new Date(val.tripEndDate)];
        this.tripDelyDateRange = [new Date(val.delayStartDate), new Date(val.delayEndDate)];
        if (val.tripStatus === "000" || (val.tripStatus === "002" && val.approvalPassFlag === "000")) {
          this.isCanEdit = true;
        } else {
          this.isCanEdit = false;
        }
        if ((val.tripStatus === "002" && val.approvalPassFlag === "001") || val.tripStatus === "003" || val.tripStatus === "004" || val.tripStatus === "005") {
          this.hasChangeTrip = true;
          this.editChangeTrip = true;
        } else if (val.tripStatus === "006" || val.tripStatus === "007" || val.tripStatus === "008" || val.tripStatus === "009") {
          this.hasChangeTrip = true;
          this.editChangeTrip = false;
        } else {
          this.hasChangeTrip = false;
        }
        if (this.hasChangeTrip) {
          if (!(val?.delayReason?.length > 0)) {
            this.hasChangeTrip = false;
          }
        }
        const arrivalDate = [];
        val.tripNodeList.forEach((ele) => {
          ele.tripList.forEach((item) => {
            if (item.arrivalDate && item.status !== "001" && item.status !== "006" && item.status !== "008" && item.status !== "017") {
              arrivalDate.push(item.arrivalDate);
            }
          });
        });
        if (arrivalDate.length) {
          this.changeDate = arrivalDate.sort();
        }
        if ((!f && val.delayStartDate) || (val.tempStartDate && val.tripStatus !== "000" && val.tripStatus !== "001")) {
          this.hasChangeDate = true;
        }
      } else {
        this.isCanEdit = true;
        this.tripDateRange = [new Date(), new Date(Number(new Date()) + 86400000)];
        this.tripDelyDateRange = [new Date(), new Date()];
      }
    },
    tripDateRange(val) {
      if (val?.length >= 2) {
        this.tripData.tripStartDate = dayjs(val[0]).format("YYYY-MM-DD");
        this.tripData.tripEndDate = dayjs(val[1]).format("YYYY-MM-DD");
      } else {
        this.tripData.tripStartDate = null;
        this.tripData.tripEndDate = null;
      }
    }
  },
  methods: {
    updateCTime(val) {
      if (dayjs(this.tripDelyDateRange[0]).format("YYYY-MM-DD") === val[0] && dayjs(this.tripDelyDateRange[1]).format("YYYY-MM-DD") === val[1]) {
        this.$message("变更时间不可等于行程期间");
        return;
      }
      this.timeChangeVisible = false;
      this.tripDelyDateRange = val;
      // 展示期间变更
      this.hasChangeDate = true;
      this.canEidtTime = false;
      this.tripData.delayStartDate = val[0];
      this.tripData.delayEndDate = val[1];
      this.changeDatePicker();
      // this.checkRepeatTripDate(p);
    },
    changeDatePicker() {
      this.$emit("hasChangeDelyDate", this.hasChangeDate);
    },
    changeTime() {
      this.timeChangeVisible = true;
    },
    delayPrefix(val) {
      if (dayjs(val.delayDate).isBefore(dayjs(this.tripData.tripStartDate))) {
        return `提前至${this.tripData.delayStartDate}`;
      }
      if (dayjs(val.delayDate).isAfter(dayjs(this.tripData.tripEndDate))) {
        return `延期至${this.tripData.delayEndDate}`;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.trip-summary-container {
  background: #fff !important;
  padding: 0 20px 16px 20px;
  border-radius: 0;
  .edit-time {
    color: rgb(73, 190, 242);
    margin-left: 8px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
  .title-row {
    height: 50px;
    display: flex;
    justify-content: space-between;
    .title {
      height: 50px;
      line-height: 50px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333;
      text-align: left;
    }
    .tripNo {
      height: 50px;
      line-height: 50px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #636c78;
      text-align: right;
    }
  }
  .date-row {
    height: 32px;
    display: flex;
    .title {
      margin-right: 13px;
      height: 32px;
      width: 50px;
      line-height: 32px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #333;
      text-align: left;
    }
  }
  .reason-row {
    display: flex;
    margin-top: 16px;
    .title {
      margin-right: 13px;
      height: 32px;
      width: 50px !important;
      line-height: 32px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #333;
      text-align: left;
    }
    .reason-input {
      display: block;
      width: 600px;
      &.wide {
        width: calc(100% - 50px);
      }
      & /deep/ .el-textarea__inner {
        padding-left: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        min-height: 52px !important;
        max-height: 100px !important;
      }
    }
  }
  .date-picker {
    height: 32px;
    width: 600px;
    & /deep/ .el-range-input {
      text-align: left;
      padding-left: 2px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
    }
    & /deep/ .el-range-separator {
      line-height: 24px !important;
      margin-top: 2px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #636c78;
      width: 30px;
    }
    & /deep/ .el-range__icon {
      display: none;
    }
    & /deep/ .el-range__close-icon {
      line-height: 32px !important;
      padding-left: 80px;
    }
  }
  & /deep/ .el-input__inner {
    padding-left: 32px;
  }
  .after-icon {
    position: absolute;
    top: 9px;
    right: 10px;
  }
  .before-icon {
    position: absolute;
    top: 8px;
    left: 10px;
  }
  .tips-icon {
    margin-left: 10px;
  }
  .look-mode-contain {
    height: auto;
    min-height: 84px;
    max-height: calc(100% - 50px - 12px);
    padding: 0 20px 12px 20px;
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    .line-contain {
      text-align: left;
      .left-title {
        display: inline-block;
        margin: 18px 12px 0 0;
        height: 13px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #333;
      }
      .content {
        line-height: 20px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #636c78;
      }
      .status {
        float: right;
        margin: 10px 0 0 0;
      }
    }
  }
}
</style>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-trip-summary-popover {
  .popper__arrow {
    &::after {
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .tips-trip-summary-popover {
    margin: -13px;
    padding: 12px;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    min-height: 25px;
    max-width: 370px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
  }
  .tips-trip-change-popover {
    margin: -13px;
    padding: 8px 12px;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    min-height: 25px;
    max-width: 800px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
    .content-contain {
      display: flex;
      align-items: center;
      padding: 2px 0;
      .dian {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        margin-bottom: 3px;
        background-color: #26c393;
      }
      .time {
        color: #b9c4d2;
        padding: 0 8px;
      }
      .text {
        color: #fff;
      }
    }
  }
}
</style>
