<template>
  <div class="en-div-hover">
    <el-popover
      v-model="showAllDetail"
      popper-class="custom-div-popover"
      placement="top"
      trigger="manual"
    >
      <div class="div-content-popover">
        {{ detail }}
      </div>
      <div
        slot="reference"
        ref="content_ref"
        class="content"
        @mouseover="showAllContentDetail"
        @mouseleave="hiddenAllContentDetail"
      >
        {{ detail }}
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  name: "EnDivHover",
  props: {
    detail: {
      type: String,
      default: ""
    }
  },
  data() {
    return { showAllDetail: false };
  },
  methods: {
    // 显示所有内容
    showAllContentDetail() {
      const el = this.$refs.content_ref;
      this.showAllDetail = el.clientWidth < el.scrollWidth;
    },
    // 隐藏内容
    hiddenAllContentDetail() {
      this.showAllDetail = false;
    }
  }
};
</script>
<style scoped lang="scss">
.en-div-hover {
  .content {
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }
}
</style>

<style lang="scss">
$bgColor: #666975 !important;

.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-div-popover {
  .popper__arrow {
    &::after {
      border-top-color: $bgColor;
      border-bottom-color: $bgColor;
    }
  }
  .div-content-popover {
    margin: -13px;
    padding: 12px;
    background-color: $bgColor;
    border: 0 solid #fff;
    border-radius: 4px;
    height: auto;
    width: auto;
    max-width: 400px !important;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
  }
}
</style>
