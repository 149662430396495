<template>
  <div class="dialog-contain">
    <el-dialog title="" :show-close="false" :visible.sync="dialogMyVisible" :close-on-click-modal="false">
      <div class="contact-body-contain">
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ fill: '#A9B5C6' }"
          ></en-icon>
        </div>

        <div class="content-contain">
          <en-tabs
            v-if="!originData"
            v-model="activeTab"
            :list="tabList"
            type="white"
            @change="changeTabs"
          >
          </en-tabs>
          <div class="div-line"></div>

          <div class="content-contain">
            <flight
              v-if="activeTab === 'flight'"
              ref="flight"
              :flight-data.sync="flightData"
              @commitData="commitData"
            ></flight>
            <hotel
              v-else-if="activeTab === 'hotel'"
              ref="hotel"
              :hotel-data.sync="hotelData"
              @commitData="commitData"
            ></hotel>
            <car
              v-else-if="activeTab === 'car'"
              ref="car"
              :car-data.sync="carData"
              @commitData="commitData"
            ></car>
            <train
              v-else-if="activeTab === 'train'"
              ref="train"
              :train-data.sync="trainData"
              @commitData="commitData"
            ></train>
            <other
              v-else-if="activeTab === 'other'"
              ref="other"
              :other-data.sync="otherData"
              @commitData="commitData"
            ></other>
          </div>

          <en-button
            size="small"
            type="blue"
            class="confirm-button"
            @click="confirmClick"
          >
            确定
          </en-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import flight from "./views/flight";
import hotel from "./views/hotel";
import car from "./views/car";
import train from "./views/train";
import other from "./views/other";

export default {
  name: "TripSupplement",
  components: {
    flight,
    hotel,
    car,
    train,
    other
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    originData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialogMyVisible: true,
      dialogContactsVisible: false,
      editContact: null,

      tabList: [
        {
          id: "flight",
          name: "机票"
        },
        {
          id: "hotel",
          name: "酒店"
        },
        {
          id: "car",
          name: "用车"
        },
        {
          id: "train",
          name: "火车"
        },
        {
          id: "other",
          name: "其他"
        }
      ],
      activeTab: "flight",

      flightData: null,
      hotelData: null,
      carData: null,
      trainData: null,
      otherData: null,
      title: "补单",
      isEdit: false
    };
  },
  watch: {
    dialogMyVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    this.dialogMyVisible = this.dialogVisible;
    if (this.originData) {
      this.updateActiveTab(this.originData.type);
      this.isEdit = true;
    } else {
      this.title = "补单";
      this.flightTab = true;
    }
  },
  methods: {
    updateActiveTab(type) {
      const originData = cloneDeep(this.originData);
      const startCity = {
        cityCode: originData?.startCityCode,
        cityName: originData?.startCity,
        enCityCode: originData?.startEnCityCode,
        enCityName: originData?.startCity
      };
      const arrivalCity = {
        cityCode: originData?.arrivalCityCode,
        cityName: originData?.arrivalCity,
        enCityCode: originData?.arrivalEnCityCode,
        enCityName: originData?.arrivalCity
      };
      if (type === "000") {
        this.title = "机票补单";
        this.activeTab = "flight";
        this.flightData = originData;
        this.flightData.startCity = startCity;
        this.flightData.arrivalCity = arrivalCity;
      } else if (type === "001") {
        this.title = "酒店补单";
        this.activeTab = "hotel";
        this.hotelData = originData;
        this.hotelData.arrivalCity = arrivalCity;
      } else if (type === "003") {
        this.title = "用车补单";
        this.activeTab = "car";
        this.carData = originData;
      } else if (type === "002") {
        this.title = "火车补单";
        this.activeTab = "train";
        this.trainData = originData;
      } else {
        this.title = "其他补单";
        this.activeTab = "other";
        this.otherData = originData;
      }
    },
    changeTabs(res) {
      // this.$refs.form.clearValidate();

      this.activeTab = "other";

      if (res.id === "flight") {
        this.activeTab = "flight";
      } else if (res.id === "hotel") {
        this.activeTab = "hotel";
      } else if (res.id === "car") {
        this.activeTab = "car";
      } else if (res.id === "train") {
        this.activeTab = "train";
      } else {
        this.activeTab = "other";
      }
    },
    closeClick() {
      this.dialogMyVisible = false;
    },
    deleteUser(item) {
      const index = this.userList.indexOf(item);
      this.userList.splice(index, 1);
    },
    addUserClick() {
      this.dialogContactsVisible = true;
    },
    getSelectContact(val) {
      const obj = {
        id: val.id,
        name: val.name
      };
      this.userList.push(obj);
    },
    confirmClick() {
      if (this.activeTab === "flight") {
        this.$refs.flight.parentHandleClick("flight");
      } else if (this.activeTab === "hotel") {
        this.$refs.hotel.parentHandleClick("hotel");
      } else if (this.activeTab === "car") {
        this.$refs.car.parentHandleClick("car");
      } else if (this.activeTab === "train") {
        this.$refs.train.parentHandleClick("train");
      } else {
        this.$refs.other.parentHandleClick("other");
      }
    },
    commitData(val) {
      this.closeClick();

      if (val.userList) {
        const userList = [...val.userList];
        let userId = "";
        for (let i = 0; i < userList?.length; i++) {
          const user = userList[i];
          if (userId.length === 0) {
            userId = user.userId;
          } else {
            userId = `${userId},${user.userId}`;
          }
        }
        val.userId = userId;
      }

      if (val.arrivalDate) {
        const arrivalDate = dayjs(val.arrivalDate).format("YYYY-MM-DD");
        val.arrivalDate = arrivalDate;
      }

      if (val.departureDate) {
        const departureDate = dayjs(val.departureDate).format("YYYY-MM-DD");
        val.departureDate = departureDate;
      }

      console.log(val);

      if (this.isEdit) {
        this.$emit("updateTripNode", val);
      } else {
        this.$emit("addNewTripNode", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 900px;
  height: 574px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: #333;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    margin-top: 42px;
    height: calc(100% - 42px);
    text-align: left;
    .div-line {
      width: 100%;
      height: 1px;
      background-color: rgba(232, 236, 242, 1);
    }
    .content-contain {
      margin-top: 20px;
      margin-left: 20px;
      width: 860px;
      height: 414px;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
    }
    .confirm-button {
      position: absolute;
      bottom: 16px;
      right: 20px;
      width: 72px;
      height: 32px;
      background-color: #4895df;
      border-radius: 4px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #fff;
      line-height: 12px;
    }
    & /deep/ .en-tabs {
      line-height: 40px;
    }
    & /deep/ .en-tabs-item {
      height: 40px;
      font-size: 12px;
      padding: 0 16px 0 16px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      text-align: center;
      width: 120px;
      &.active {
        color: #3e90fe;
      }
    }
  }
}

.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
/deep/ .en-tabs .en-tabs-list .active_bar{
  bottom: -2px;
}
</style>
