var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "en-div-hover" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "custom-div-popover",
            placement: "top",
            trigger: "manual",
          },
          model: {
            value: _vm.showAllDetail,
            callback: function ($$v) {
              _vm.showAllDetail = $$v
            },
            expression: "showAllDetail",
          },
        },
        [
          _c("div", { staticClass: "div-content-popover" }, [
            _vm._v(" " + _vm._s(_vm.detail) + " "),
          ]),
          _c(
            "div",
            {
              ref: "content_ref",
              staticClass: "content",
              attrs: { slot: "reference" },
              on: {
                mouseover: _vm.showAllContentDetail,
                mouseleave: _vm.hiddenAllContentDetail,
              },
              slot: "reference",
            },
            [_vm._v(" " + _vm._s(_vm.detail) + " ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }