<!--
 * @Author: zhulin
 * @Date: 2021-09-02 15:43:22
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-03 10:21:12
 * @Description: 行程期间变更事由
 * @FilePath: \user\src\views\businessTravel\trip\components\add-change-reason\index.vue
-->
<template>
  <en-dialog
    title="行程期间变更事由"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="700px"
    @close="close">
    <div class="cent">
    <el-input
      ref="comment"
      class="comment"
      type="textarea"
      maxlength="100"
      placeholder="请输入行程期间变更事由（100字以内）"
      v-model="inputText"
      :rows="10"
      @focus="focusInput=true"
      @blur="focusInput=false"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm()">确定</en-button>
    </span>
  </en-dialog>
</template>

<script>
export default {
  name: "addChangeDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputText: ""
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm() {
      if (this.inputText === "") {
        this.$message("请输入行程期间变更事由！");
      }
      this.$emit("getChangeReason", this.inputText);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
