<template>
  <div class="user-list-container">
    <div class="user-contain">
      <div v-for="item in dataList" :key="item.key" class="user-detail-contain">
        <en-user-logo
          :user-name="item.name"
          size="30"
          class="user-icon"
        ></en-user-logo>
        <div @click="deleteUser(item)">
          <en-icon
            class="user-delete"
            name="shibai"
            size="14px"
            :style="{ fill: '#F76B6B' }"
          ></en-icon>
        </div>
        <span class="user-name">
          {{ item.name }}
        </span>
      </div>

      <div class="user-detail-contain" style="width:32px;">
        <div class="add-contain" @click="addUserClick">
          <en-icon
            name="tianjia-anniutoubu"
            size="10px"
            :style="{ fill: '#3e90fe' }"
            class="add-icon"
          ></en-icon>
        </div>
      </div>
    </div>

    <enContacts
      v-if="contactsVisible"
      :dialog-visible.sync="contactsVisible"
      :edit-contact="editContact"
      @getEnContactConfirm="getSelectContact"
    />
  </div>
</template>

<script>
import enContacts from "../../../components/en-contacts";

export default {
  name: "UserList",
  components: { enContacts },
  props: {
    userList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      contactsVisible: false,
      editContact: null,
      dataList: []
    };
  },
  watch: {
    dataList(val) {
      this.$emit("update:userList", val);
    },
    userList(val) {
      this.dataList = val;
    }
  },
  methods: {
    deleteUser(item) {
      const index = this.userList.indexOf(item);
      this.userList.splice(index, 1);
    },
    addUserClick() {
      this.contactsVisible = true;
    },
    getSelectContact(val) {
      const obj = {
        id: val.id,
        name: val.name
      };
      this.userList.push(obj);
    }
  }
};
</script>

<style lang="scss" scoped>
.user-list-container {
  .user-contain {
    margin-left: 20px;
    margin-top: 5px;
    min-height: 50px;
    width: 820px;
    display: flex;
    flex-wrap: wrap;
    .user-detail-contain {
      position: relative;
      display: inline-block;
      height: 50px;
      width: auto;
      .user-icon {
        position: absolute;
        top: 5px;
      }
      .user-delete {
        position: absolute;
        left: 20px;
      }
      .user-name {
        margin: 5px 20px 0 40px;
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #636c78;
      }
      .add-contain {
        position: absolute;
        top: 5px;
        height: 30px;
        width: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 15px;
        text-align: center;
        .add-icon {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
