<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogOccupyVisible"
    >
      <div class="body-contain">
        <span class="title">{{ title }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{fill:'#A9B5C6'}"
          ></en-icon>
        </div>

        <div class="content-contain">
          <el-table
            stripe
            border
            :row-class-name="tableRowClassName"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            height="510"
            class="content-table"
            @selection-change="handleSelectionChange"
          >
            <el-table-column width="60">
              <template slot-scope="scope">
                <el-radio
                  v-model="radio"
                  :label="scope.row.id"
                  @change="radioChange(scope)"
                >
                </el-radio>
              </template>
            </el-table-column>

            <el-table-column
              label="组织机构/科目"
              show-overflow-tooltip
            >
              <template slot="header">
                <div class="custom-header">
                  组织机构 / 科目
                </div>
              </template>

              <template slot-scope="scope">
                <div class="custom-row">
                  {{ scope.row.department + "/" + scope.row.subject }}
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="amount">
              <template slot="header">
                <div class="custom-header">
                  金额
                </div>
              </template>
              <template slot-scope="scope">
                <div class="custom-row">
                  {{ scope.row.amount }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <en-button
          :disabled="!selectData"
          class="confirm-button"
          @click="confirmClick"
        >
          确定
        </en-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EnTripOccupyDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogOccupyVisible: true,
      title: "预算占用",
      radio: "",
      selectData: null,
      tableData: []
    };
  },
  watch: {
    dialogOccupyVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    const list = [];
    for (let i = 0; i < 20; i++) {
      const amount = `${1800 + i * 55}.00`;
      const department = `研发部${i}`;
      const subject = `科目${i}`;
      list.push({
        id: i,
        amount,
        department,
        subject
      });
    }
    this.tableData = [...list];
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    closeClick() {
      this.dialogOccupyVisible = false;
    },
    radioChange(scope) {
      this.radio = scope.row.id;
      this.selectData = scope.row;
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    confirmClick() {
      this.$notify({
        type: "success",
        title: "温馨提示",
        message: `您选择了第${this.selectData.index}条数据`
      });
      this.dialogOccupyVisible = false;

      this.$emit("getTripOccupyValue", this.selectData);
    },
    doDraw() {
    //  获取canvas
      let canvas = document.getElementById("mycanvas");
      //  由于弹窗，确保已获取到
      /* eslint-disable */
      const a = setInterval(() => {
        //  重复获取
        canvas = document.getElementById("mycanvas");
        if (!canvas) {
          return false;
        } else {
          clearInterval(a);
          //  可以理解为一个画笔，可画路径、矩形、文字、图像
          const context = canvas.getContext("2d");
          // context.strokeStyle = "red";
          // context.lineWidth = 2;
          // context.strokeRect(5, 5, 50, 20);

          // context.moveTo(100,5);
          // context.lineTo(120,10);
          // context.strokeStyle = "yellow";
          // context.lineWidth = 2;
          // context.stroke();

          // context.font = "20px Arial bolder";
          // context.fillStyle = "red";
          // context.fillText("汇总文字",150,20);

          const startR = Math.PI * (1 / 2);
          const endR = Math.PI * (-1 / 2);
          // context.clearRect(200, 0, 50, 50);
          context.save();
          context.beginPath();
          context.lineCap = "round";
          context.strokeStyle = "red";
          context.fillStyle = "#F5F7FA";

          context.lineWidth = 1;
          context.arc(25, 25, 7, startR, endR, true);
          // context.closePath();
          context.stroke();
          context.fill();
        }
      }, 1);
      /* eslint-enable */
    }

  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1000px;
  height: 660px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    margin: 52px 20px 16px 20px;
    width: 960px;
    height: 510px;
    .content-table {
      .custom-header {
        display: inline-block;
        padding: 0;
        margin: 0 15px 0 3px;
        height: 50px;
        line-height: 50px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #333;
      }
      .custom-row {
        display: inline-block;
        padding: 0;
        margin: 0 15px 0 3px;
        height: 46px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #636c78;
        line-height: 46px;
      }
    }
    & /deep/ .el-radio {
      margin-left: 12px;
      .el-radio__label {
        display: none;
      }
    }
    & /deep/ .el-table--border {
      border-radius: 4px;
      border: 1px solid #dce5ec;
    }
    & /deep/ .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      display: none;
    }
  }
  .confirm-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 72px;
    height: 32px;
  }
  #mycanvas {
    border: 1px solid rgb(199, 198, 198);
  }
}
.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
