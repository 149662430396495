<template>
  <div class="section-title-container">
    {{ sectionText }}
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    sectionName: {
      type: String,
      default: ""
    }
  },
  data() {
    return { sectionText: "" };
  },
  mounted() {
    if (this.sectionName) {
      this.sectionText = this.sectionName;
    }
  }
};
</script>

<style lang="scss" scoped>
.section-title-container {
  margin-top: 19px;
  margin-left: 21px;
  margin-bottom: 14px;
  width: 820px !important;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #333;
}
</style>
