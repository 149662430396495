<template>
  <!-- v-recordScroll="'.el-table__body-wrapper'" 这玩意不知道干嘛用的,导致我无法push -->
  <div class="trip-contain">
    <div class="head-contain">
      <span class="title">我的行程</span>
      <!-- UI修改,暂时屏蔽
      <el-input
        v-model="searchInput"
        class="search"
        suffix-icon="el-icon-search"
        placeholder="请输入关键字"
        @change="searchInputChange"
      ></el-input>
      -->
      <en-button
        class="add-button"
        icon="tianjia-anniutoubu"
        icon-color="#fff"
        @click="addButtonClick"
      >
        添加
      </en-button>
    </div>
    <div class="body-contain">
      <en-table
        border
        stripe
        class="my-table"
        :row-class-name="tableRowClassName"
        :data="tableData"
        :height="tableHeight"
        @row-click="tableRowClick"
        :height-config="{bottomHeight:10}"
        @filter-sort-change="handleFilterSortChange"
        :page-config="{pageModel, changeMethod: changePage}"
      >
        <en-table-column
          v-for="item in tableHeadList"
          :key="item.prop"
          :label="item.title"
          :data="item"
          :filter-sort-config="{}"
          :width="item.width > 0 ? item.width : ''"
        >
          <!-- 表格内容自定义 -->
          <template slot-scope="scope">
            <!-- 行程期间 -->
            <span v-if="item.prop === 'tripDateRange'" class="other-content">{{
              scope.row.tripStartDate + " / " + scope.row.tripEndDate
            }}</span>

            <!-- 行程状态 -->
            <en-trip-bill-status
              v-else-if="item.prop === 'tripStatus'"
              :status="Number(scope.row.tripStatus)"
            ></en-trip-bill-status>

            <!-- 行程单编号 -->
            <div v-else-if="item.prop === 'tripNo'">
              <span class="other-content">{{
                scope.row[scope.column.property]
              }}</span>
              <en-icon
                v-if="isShareTrip(scope.row.createId)"
                size="12px"
                class="gongxiang-icon"
                :style="{ fill: '#ffad2c' }"
                name="gongxiang-wodehangcheng"
              ></en-icon>
            </div>

            <!-- 表格其他内容 -->
            <span v-else class="other-content">{{
              scope.row[scope.column.property]
            }}</span>
          </template>
        </en-table-column>
      </en-table>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { tripService } from "@/api";
import Store from "@/store";
import { enEqual } from "@/tools/compare";
import enTripBillStatus from "../components/en-trip-bill-status";

export default {
  name: "Trip",
  components: {
    enTripBillStatus
  },
  data() {
    return {
      searchInput: "",
      dropdownInput: "",
      tableShowIndex: -1,
      tableHeadList: [
        {
          title: "行程单编号",
          prop: "tripNo",
          width: 192,
          noSort: 1, // 1排序 0不排序
          noSearch: 1, // 1筛选 0不筛选
          type: "001" // 文本
        },
        {
          title: "行程期间",
          prop: "tripDateRange",
          width: 182,
          noSort: 1, // 1排序 0不排序
          noSearch: 1, // 1筛选 0不筛选
          type: "001" // 文本
        },
        {
          title: "行程事由",
          prop: "tripReason",
          width: -1,
          noSort: 1, // 1排序 0不排序
          noSearch: 1, // 1筛选 0不筛选
          type: "001" // 文本
        },
        {
          title: "行程状态",
          prop: "tripStatus",
          width: 188,
          noSort: 1, // 1排序 0不排序
          noSearch: 1, // 1筛选 0不筛选
          type: "001" // 文本
        }
      ],
      screenWidth: "",
      screenHeight: "",
      tableWidth: " ",
      tableHeight: " ",
      pageModel: {
        pageNo: 1,
        pageSize: 100,
        totalPages: 1,
        totalCount: 1
      },
      // 行程请求参数
      queryData: {
        tripNo: "",
        tripStartDate: "",
        tripEndDate: "",
        tripTotalAmount: "",
        tripReason: "",
        tripStatus: "",
        pageSize: 100, // 每页数
        pageNo: 1// 当前页码
      },

      // 行程列表数据
      tripDataSource: []
      // tripDataSource: {
      //   records: [
      //     {
      //       approvalPassFlag: "000",
      //       createTime: 1596418502000,
      //       defaultPage: 1,
      //       modifyTime: 1596418502000,
      //       tempEndDate: "2020-08-04",
      //       tempStartDate: "2020-08-03",
      //       tenantId: "225062062644002816",
      //       createId: "230144701093642240",
      //
      //       id: "322524705533231104", // 数据ID
      //       tripNo: "TR2020080300011",
      //       tripStartDate: "2020-08-03",
      //       tripEndDate: "2020-08-04",
      //       tripTotalAmount: "182.00",
      //       tripReason: "行程事由",
      //       tripStatus: "000"
      //     }
      //   ],
      //   hasFirst: "",
      //   hasLast: "",
      //   hasNext: "",
      //   hasPrevious: "",
      //   pageNo: "",
      //   pageSize: "",
      //   total: "",
      //   totalPages: ""
      // }
    };
  },
  watch: {
    screenWidth() {
      // console.log("screenWidth:" + val);
      const w = parseInt(this.screenWidth, 10) - 56;
      this.tableWidth = `width: ${w}px`;
    },
    screenHeight() {
      // console.log("screenHeight:" + val);
      this.tableHeight = parseInt(this.screenHeight, 10) - 152;
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    const self = this;
    window.onresize = () => (() => {
      self.screenWidth = document.body.clientWidth;
      self.screenHeight = document.body.clientHeight;
    })();
  },
  activated() {
    this.queryMyTripList();
  },
  computed: {
    tableData() {
      return this.tripDataSource;
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    changePage(val) {
      if (val) {
        this.queryData.pageNo = val.pageNo;
        this.queryData.pageSize = val.pageSize;
        this.queryMyTripList();
      }
    },
    // 是否是共享行程
    isShareTrip(createId) {
      if (enEqual(createId, Store.getters.userInfo.userId)) {
        return false;
      }
      return true;
    },
    // 请求我的行程
    @request(true)
    async queryMyTripList() {
      const params = {
        pageNo: this.queryData.pageNo,
        pageSize: this.queryData.pageSize,
        tripNo: this.queryData.tripNo,
        tripStartDate: this.queryData.tripStartDate,
        tripEndDate: this.queryData.tripEndDate,
        tripTotalAmount: this.queryData.tripTotalAmount,
        tripReason: this.queryData.tripReason,
        tripStatus: this.queryData.tripStatus
      };
      this.tripDataSource = await tripService.queryMyTrip(params);
    },
    // 新增
    addButtonClick() {
      this.$router.push({
        path: "/business-travel/trip/tripDetail"
      });
    },
    // 查看详情
    tableRowClick(row) {
      const rowData = row.row;
      this.$router.push({
        path: "/business-travel/trip/tripDetail",
        query: {
          tripId: rowData.id
        }
      });
    },
    // 筛选
    handleFilterSortChange(obj) {
      console.log(obj);
      if (obj?.column) {
        if (obj.prop === "tripDateRange") {
          this.queryData.tripStartDate = obj.value;
        } else {
          this.queryData[obj.prop] = obj.value;
        }
      } else {
        this.queryData.tripNo = "";
        this.queryData.tripStartDate = "";
        this.queryData.tripEndDate = "";
        this.queryData.tripReason = "";
        this.queryData.tripStatus = "";
        this.searchInput = "";
      }
      this.queryMyTripList();
    },
    // 搜索
    searchInputChange() {
      this.queryData.tripReason = this.searchInput;
      this.queryMyTripList();
    }
  }
};
</script>

<style lang="scss" scoped>
.header-dropdown {
  width: 300px;
  height: 94px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  .dropdown-input {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 276px;
    & /deep/ .el-input__inner {
      padding-left: 12px;
    }
    & /deep/ .el-input__icon {
      line-height: 32px !important;
    }
  }
  .dropdown-button {
    position: absolute;
    padding: 0 15px;
    bottom: 12px;
    right: 12px;
    height: 28px;
    width: 62px;
  }
}
.trip-contain {
  background: #fff !important;
  width: 100%;
  height: 100%;

  .head-contain {
    position: relative;
    height: 48px;
    border-bottom: 1px solid #e8ecf2;
    .title {
      display: block;
      margin-left: 20px;
      width: 80px;
      height: 48px;
      line-height: 49px;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      color: #333;
    }
    .search {
      position: absolute;
      top: 9px;
      right: 104px;
      width: 400px;
      height: 32px;
      & /deep/ .el-input__icon {
        line-height: 32px;
      }
    }
    .add-button {
      position: absolute;
      top: 9px;
      right: 20px;
      width: 72px;
      height: 32px;
    }
  }

  .body-contain {
    padding: 12px 0;
    height: calc(100% - 48px);
    // border-bottom: 1px solid red;
    /*&/deep/.en-table {*/
    /*  height: calc(100% - 55px);*/
    /*}*/
    /*.my-table {*/
    /*  height: 500px;*/
    /*}*/
    /*margin: 12px 0;*/
    .gongxiang-icon {
      margin-left: 10px;
    }
  }
}
</style>
